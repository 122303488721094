import { EIso3166OneAlpha2, EIso3166OneAlpha2CountryGroup, EIso3166OneAlpha2CountryGroupEnglish } from "@devowl-wp/iso-codes";
function getDataProcessingInSelectOptions(t) {
    const groups = [];
    for(const key in EIso3166OneAlpha2CountryGroup){
        if (isNaN(Number(key))) {
            groups.push({
                label: EIso3166OneAlpha2CountryGroupEnglish[key],
                value: EIso3166OneAlpha2CountryGroup[key]
            });
        }
    }
    const singles = [];
    for(const key in EIso3166OneAlpha2){
        if (isNaN(Number(key))) {
            singles.push({
                label: `${key.replace(/([a-z])([A-Z])/g, "$1 $2")} (${EIso3166OneAlpha2[key]})`,
                value: EIso3166OneAlpha2[key]
            });
        }
    }
    return [
        {
            label: t("Groups"),
            options: groups.sort((a, b)=>a.label.localeCompare(b.label))
        },
        {
            label: t("Countries"),
            options: singles
        }
    ];
}
export { getDataProcessingInSelectOptions };
