var EIso3166OneAlpha2;
(function(EIso3166OneAlpha2) {
    EIso3166OneAlpha2["Afghanistan"] = "AF";
    EIso3166OneAlpha2["AlandIslands"] = "AX";
    EIso3166OneAlpha2["Albania"] = "AL";
    EIso3166OneAlpha2["Algeria"] = "DZ";
    EIso3166OneAlpha2["AmericanSamoa"] = "AS";
    EIso3166OneAlpha2["Andorra"] = "AD";
    EIso3166OneAlpha2["Angola"] = "AO";
    EIso3166OneAlpha2["Anguilla"] = "AI";
    EIso3166OneAlpha2["Antarctica"] = "AQ";
    EIso3166OneAlpha2["AntiguaAndBarbuda"] = "AG";
    EIso3166OneAlpha2["Argentina"] = "AR";
    EIso3166OneAlpha2["Armenia"] = "AM";
    EIso3166OneAlpha2["Aruba"] = "AW";
    EIso3166OneAlpha2["Australia"] = "AU";
    EIso3166OneAlpha2["Austria"] = "AT";
    EIso3166OneAlpha2["Azerbaijan"] = "AZ";
    EIso3166OneAlpha2["Bahamas"] = "BS";
    EIso3166OneAlpha2["Bahrain"] = "BH";
    EIso3166OneAlpha2["Bangladesh"] = "BD";
    EIso3166OneAlpha2["Barbados"] = "BB";
    EIso3166OneAlpha2["Belarus"] = "BY";
    EIso3166OneAlpha2["Belgium"] = "BE";
    EIso3166OneAlpha2["Belize"] = "BZ";
    EIso3166OneAlpha2["Benin"] = "BJ";
    EIso3166OneAlpha2["Bermuda"] = "BM";
    EIso3166OneAlpha2["Bhutan"] = "BT";
    EIso3166OneAlpha2["Bolivia"] = "BO";
    EIso3166OneAlpha2["BosniaAndHerzegovina"] = "BA";
    EIso3166OneAlpha2["Botswana"] = "BW";
    EIso3166OneAlpha2["BouvetIsland"] = "BV";
    EIso3166OneAlpha2["Brazil"] = "BR";
    EIso3166OneAlpha2["BritishIndianOceanTerritory"] = "IO";
    EIso3166OneAlpha2["BruneiDarussalam"] = "BN";
    EIso3166OneAlpha2["Bulgaria"] = "BG";
    EIso3166OneAlpha2["BurkinaFaso"] = "BF";
    EIso3166OneAlpha2["Burundi"] = "BI";
    EIso3166OneAlpha2["Cambodia"] = "KH";
    EIso3166OneAlpha2["Cameroon"] = "CM";
    EIso3166OneAlpha2["Canada"] = "CA";
    EIso3166OneAlpha2["CapeVerde"] = "CV";
    EIso3166OneAlpha2["CaymanIslands"] = "KY";
    EIso3166OneAlpha2["CentralAfricanRepublic"] = "CF";
    EIso3166OneAlpha2["Chad"] = "TD";
    EIso3166OneAlpha2["Chile"] = "CL";
    EIso3166OneAlpha2["China"] = "CN";
    EIso3166OneAlpha2["ChristmasIsland"] = "CX";
    EIso3166OneAlpha2["CocosKeelingIslands"] = "CC";
    EIso3166OneAlpha2["Colombia"] = "CO";
    EIso3166OneAlpha2["Comoros"] = "KM";
    EIso3166OneAlpha2["Congo"] = "CG";
    EIso3166OneAlpha2["CongoDemocraticRepublic"] = "CD";
    EIso3166OneAlpha2["CookIslands"] = "CK";
    EIso3166OneAlpha2["CostaRica"] = "CR";
    EIso3166OneAlpha2["CoteDIvoire"] = "CI";
    EIso3166OneAlpha2["Croatia"] = "HR";
    EIso3166OneAlpha2["Cuba"] = "CU";
    EIso3166OneAlpha2["Cyprus"] = "CY";
    EIso3166OneAlpha2["CzechRepublic"] = "CZ";
    EIso3166OneAlpha2["Denmark"] = "DK";
    EIso3166OneAlpha2["Djibouti"] = "DJ";
    EIso3166OneAlpha2["Dominica"] = "DM";
    EIso3166OneAlpha2["DominicanRepublic"] = "DO";
    EIso3166OneAlpha2["Ecuador"] = "EC";
    EIso3166OneAlpha2["Egypt"] = "EG";
    EIso3166OneAlpha2["ElSalvador"] = "SV";
    EIso3166OneAlpha2["EquatorialGuinea"] = "GQ";
    EIso3166OneAlpha2["Eritrea"] = "ER";
    EIso3166OneAlpha2["Estonia"] = "EE";
    EIso3166OneAlpha2["Ethiopia"] = "ET";
    EIso3166OneAlpha2["FalklandIslandsMalvinas"] = "FK";
    EIso3166OneAlpha2["FaroeIslands"] = "FO";
    EIso3166OneAlpha2["Fiji"] = "FJ";
    EIso3166OneAlpha2["Finland"] = "FI";
    EIso3166OneAlpha2["France"] = "FR";
    EIso3166OneAlpha2["FrenchGuiana"] = "GF";
    EIso3166OneAlpha2["FrenchPolynesia"] = "PF";
    EIso3166OneAlpha2["FrenchSouthernTerritories"] = "TF";
    EIso3166OneAlpha2["Gabon"] = "GA";
    EIso3166OneAlpha2["Gambia"] = "GM";
    EIso3166OneAlpha2["Georgia"] = "GE";
    EIso3166OneAlpha2["Germany"] = "DE";
    EIso3166OneAlpha2["Ghana"] = "GH";
    EIso3166OneAlpha2["Gibraltar"] = "GI";
    EIso3166OneAlpha2["Greece"] = "GR";
    EIso3166OneAlpha2["Greenland"] = "GL";
    EIso3166OneAlpha2["Grenada"] = "GD";
    EIso3166OneAlpha2["Guadeloupe"] = "GP";
    EIso3166OneAlpha2["Guam"] = "GU";
    EIso3166OneAlpha2["Guatemala"] = "GT";
    EIso3166OneAlpha2["Guernsey"] = "GG";
    EIso3166OneAlpha2["Guinea"] = "GN";
    EIso3166OneAlpha2["GuineaBissau"] = "GW";
    EIso3166OneAlpha2["Guyana"] = "GY";
    EIso3166OneAlpha2["Haiti"] = "HT";
    EIso3166OneAlpha2["HeardIslandAndMcdonaldIslands"] = "HM";
    EIso3166OneAlpha2["HolySeeVaticanCityState"] = "VA";
    EIso3166OneAlpha2["Honduras"] = "HN";
    EIso3166OneAlpha2["HongKong"] = "HK";
    EIso3166OneAlpha2["Hungary"] = "HU";
    EIso3166OneAlpha2["Iceland"] = "IS";
    EIso3166OneAlpha2["India"] = "IN";
    EIso3166OneAlpha2["Indonesia"] = "ID";
    EIso3166OneAlpha2["IranIslamicRepublicOf"] = "IR";
    EIso3166OneAlpha2["Iraq"] = "IQ";
    EIso3166OneAlpha2["Ireland"] = "IE";
    EIso3166OneAlpha2["IsleOfMan"] = "IM";
    EIso3166OneAlpha2["Israel"] = "IL";
    EIso3166OneAlpha2["Italy"] = "IT";
    EIso3166OneAlpha2["Jamaica"] = "JM";
    EIso3166OneAlpha2["Japan"] = "JP";
    EIso3166OneAlpha2["Jersey"] = "JE";
    EIso3166OneAlpha2["Jordan"] = "JO";
    EIso3166OneAlpha2["Kazakhstan"] = "KZ";
    EIso3166OneAlpha2["Kenya"] = "KE";
    EIso3166OneAlpha2["Kiribati"] = "KI";
    EIso3166OneAlpha2["Korea"] = "KR";
    EIso3166OneAlpha2["NorthKorea"] = "KP";
    EIso3166OneAlpha2["Kuwait"] = "KW";
    EIso3166OneAlpha2["Kyrgyzstan"] = "KG";
    EIso3166OneAlpha2["LaoPeoplesDemocraticRepublic"] = "LA";
    EIso3166OneAlpha2["Latvia"] = "LV";
    EIso3166OneAlpha2["Lebanon"] = "LB";
    EIso3166OneAlpha2["Lesotho"] = "LS";
    EIso3166OneAlpha2["Liberia"] = "LR";
    EIso3166OneAlpha2["LibyanArabJamahiriya"] = "LY";
    EIso3166OneAlpha2["Liechtenstein"] = "LI";
    EIso3166OneAlpha2["Lithuania"] = "LT";
    EIso3166OneAlpha2["Luxembourg"] = "LU";
    EIso3166OneAlpha2["Macao"] = "MO";
    EIso3166OneAlpha2["Macedonia"] = "MK";
    EIso3166OneAlpha2["Madagascar"] = "MG";
    EIso3166OneAlpha2["Malawi"] = "MW";
    EIso3166OneAlpha2["Malaysia"] = "MY";
    EIso3166OneAlpha2["Maldives"] = "MV";
    EIso3166OneAlpha2["Mali"] = "ML";
    EIso3166OneAlpha2["Malta"] = "MT";
    EIso3166OneAlpha2["MarshallIslands"] = "MH";
    EIso3166OneAlpha2["Martinique"] = "MQ";
    EIso3166OneAlpha2["Mauritania"] = "MR";
    EIso3166OneAlpha2["Mauritius"] = "MU";
    EIso3166OneAlpha2["Mayotte"] = "YT";
    EIso3166OneAlpha2["Mexico"] = "MX";
    EIso3166OneAlpha2["MicronesiaFederatedStatesOf"] = "FM";
    EIso3166OneAlpha2["Moldova"] = "MD";
    EIso3166OneAlpha2["Monaco"] = "MC";
    EIso3166OneAlpha2["Mongolia"] = "MN";
    EIso3166OneAlpha2["Montenegro"] = "ME";
    EIso3166OneAlpha2["Montserrat"] = "MS";
    EIso3166OneAlpha2["Morocco"] = "MA";
    EIso3166OneAlpha2["Mozambique"] = "MZ";
    EIso3166OneAlpha2["Myanmar"] = "MM";
    EIso3166OneAlpha2["Namibia"] = "NA";
    EIso3166OneAlpha2["Nauru"] = "NR";
    EIso3166OneAlpha2["Nepal"] = "NP";
    EIso3166OneAlpha2["Netherlands"] = "NL";
    EIso3166OneAlpha2["NetherlandsAntilles"] = "AN";
    EIso3166OneAlpha2["NewCaledonia"] = "NC";
    EIso3166OneAlpha2["NewZealand"] = "NZ";
    EIso3166OneAlpha2["Nicaragua"] = "NI";
    EIso3166OneAlpha2["Niger"] = "NE";
    EIso3166OneAlpha2["Nigeria"] = "NG";
    EIso3166OneAlpha2["Niue"] = "NU";
    EIso3166OneAlpha2["NorfolkIsland"] = "NF";
    EIso3166OneAlpha2["NorthernMarianaIslands"] = "MP";
    EIso3166OneAlpha2["Norway"] = "NO";
    EIso3166OneAlpha2["Oman"] = "OM";
    EIso3166OneAlpha2["Pakistan"] = "PK";
    EIso3166OneAlpha2["Palau"] = "PW";
    EIso3166OneAlpha2["PalestinianTerritoryOccupied"] = "PS";
    EIso3166OneAlpha2["Panama"] = "PA";
    EIso3166OneAlpha2["PapuaNewGuinea"] = "PG";
    EIso3166OneAlpha2["Paraguay"] = "PY";
    EIso3166OneAlpha2["Peru"] = "PE";
    EIso3166OneAlpha2["Philippines"] = "PH";
    EIso3166OneAlpha2["Pitcairn"] = "PN";
    EIso3166OneAlpha2["Poland"] = "PL";
    EIso3166OneAlpha2["Portugal"] = "PT";
    EIso3166OneAlpha2["PuertoRico"] = "PR";
    EIso3166OneAlpha2["Qatar"] = "QA";
    EIso3166OneAlpha2["Reunion"] = "RE";
    EIso3166OneAlpha2["Romania"] = "RO";
    EIso3166OneAlpha2["RussianFederation"] = "RU";
    EIso3166OneAlpha2["Rwanda"] = "RW";
    EIso3166OneAlpha2["SaintBarthelemy"] = "BL";
    EIso3166OneAlpha2["SaintHelena"] = "SH";
    EIso3166OneAlpha2["SaintKittsAndNevis"] = "KN";
    EIso3166OneAlpha2["SaintLucia"] = "LC";
    EIso3166OneAlpha2["SaintMartin"] = "MF";
    EIso3166OneAlpha2["SaintPierreAndMiquelon"] = "PM";
    EIso3166OneAlpha2["SaintVincentAndGrenadines"] = "VC";
    EIso3166OneAlpha2["Samoa"] = "WS";
    EIso3166OneAlpha2["SanMarino"] = "SM";
    EIso3166OneAlpha2["SaoTomeAndPrincipe"] = "ST";
    EIso3166OneAlpha2["SaudiArabia"] = "SA";
    EIso3166OneAlpha2["Senegal"] = "SN";
    EIso3166OneAlpha2["Serbia"] = "RS";
    EIso3166OneAlpha2["Seychelles"] = "SC";
    EIso3166OneAlpha2["SierraLeone"] = "SL";
    EIso3166OneAlpha2["Singapore"] = "SG";
    EIso3166OneAlpha2["Slovakia"] = "SK";
    EIso3166OneAlpha2["Slovenia"] = "SI";
    EIso3166OneAlpha2["SolomonIslands"] = "SB";
    EIso3166OneAlpha2["Somalia"] = "SO";
    EIso3166OneAlpha2["SouthAfrica"] = "ZA";
    EIso3166OneAlpha2["SouthGeorgiaAndSandwichIsl"] = "GS";
    EIso3166OneAlpha2["Spain"] = "ES";
    EIso3166OneAlpha2["SriLanka"] = "LK";
    EIso3166OneAlpha2["Sudan"] = "SD";
    EIso3166OneAlpha2["Suriname"] = "SR";
    EIso3166OneAlpha2["SvalbardAndJanMayen"] = "SJ";
    EIso3166OneAlpha2["Swaziland"] = "SZ";
    EIso3166OneAlpha2["Sweden"] = "SE";
    EIso3166OneAlpha2["Switzerland"] = "CH";
    EIso3166OneAlpha2["SyrianArabRepublic"] = "SY";
    EIso3166OneAlpha2["Taiwan"] = "TW";
    EIso3166OneAlpha2["Tajikistan"] = "TJ";
    EIso3166OneAlpha2["Tanzania"] = "TZ";
    EIso3166OneAlpha2["Thailand"] = "TH";
    EIso3166OneAlpha2["TimorLeste"] = "TL";
    EIso3166OneAlpha2["Togo"] = "TG";
    EIso3166OneAlpha2["Tokelau"] = "TK";
    EIso3166OneAlpha2["Tonga"] = "TO";
    EIso3166OneAlpha2["TrinidadAndTobago"] = "TT";
    EIso3166OneAlpha2["Tunisia"] = "TN";
    EIso3166OneAlpha2["Turkey"] = "TR";
    EIso3166OneAlpha2["Turkmenistan"] = "TM";
    EIso3166OneAlpha2["TurksAndCaicosIslands"] = "TC";
    EIso3166OneAlpha2["Tuvalu"] = "TV";
    EIso3166OneAlpha2["Uganda"] = "UG";
    EIso3166OneAlpha2["Ukraine"] = "UA";
    EIso3166OneAlpha2["UnitedArabEmirates"] = "AE";
    EIso3166OneAlpha2["UnitedKingdom"] = "GB";
    EIso3166OneAlpha2["UnitedStates"] = "US";
    EIso3166OneAlpha2["UnitedStatesOutlyingIslands"] = "UM";
    EIso3166OneAlpha2["Uruguay"] = "UY";
    EIso3166OneAlpha2["Uzbekistan"] = "UZ";
    EIso3166OneAlpha2["Vanuatu"] = "VU";
    EIso3166OneAlpha2["Venezuela"] = "VE";
    EIso3166OneAlpha2["Vietnam"] = "VN";
    EIso3166OneAlpha2["VirginIslandsBritish"] = "VG";
    EIso3166OneAlpha2["VirginIslandsUS"] = "VI";
    EIso3166OneAlpha2["WallisAndFutuna"] = "WF";
    EIso3166OneAlpha2["WesternSahara"] = "EH";
    EIso3166OneAlpha2["Yemen"] = "YE";
    EIso3166OneAlpha2["Zambia"] = "ZM";
    EIso3166OneAlpha2["Zimbabwe"] = "ZW";
})(EIso3166OneAlpha2 || (EIso3166OneAlpha2 = {}));
var EIso3166OneAlpha2CountryGroup;
(function(EIso3166OneAlpha2CountryGroup) {
    EIso3166OneAlpha2CountryGroup["AWS"] = "AWS";
    EIso3166OneAlpha2CountryGroup["CLOUDFLARE"] = "CLOUDFLARE";
    EIso3166OneAlpha2CountryGroup["EU"] = "EU";
    EIso3166OneAlpha2CountryGroup["EEA"] = "EEA";
    EIso3166OneAlpha2CountryGroup["FASTLY"] = "FASTLY";
    EIso3166OneAlpha2CountryGroup["GOOGLECLOUD"] = "GOOGLECLOUD";
    EIso3166OneAlpha2CountryGroup["AUTOMATTIC"] = "AUTOMATTIC";
    EIso3166OneAlpha2CountryGroup["AKAMAI"] = "AKAMAI";
    EIso3166OneAlpha2CountryGroup["BUNNYNET"] = "BUNNYNET";
    EIso3166OneAlpha2CountryGroup["GCORE"] = "GCORE";
    EIso3166OneAlpha2CountryGroup["MICROSOFTAZURE"] = "MICROSOFTAZURE";
})(EIso3166OneAlpha2CountryGroup || (EIso3166OneAlpha2CountryGroup = {}));
var EIso3166OneAlpha2EuCountries;
(function(EIso3166OneAlpha2EuCountries) {
    EIso3166OneAlpha2EuCountries["Austria"] = "AT";
    EIso3166OneAlpha2EuCountries["Belgium"] = "BE";
    EIso3166OneAlpha2EuCountries["Bulgaria"] = "BG";
    EIso3166OneAlpha2EuCountries["Croatia"] = "HR";
    EIso3166OneAlpha2EuCountries["Cyprus"] = "CY";
    EIso3166OneAlpha2EuCountries["CzechRepublic"] = "CZ";
    EIso3166OneAlpha2EuCountries["Denmark"] = "DK";
    EIso3166OneAlpha2EuCountries["Estonia"] = "EE";
    EIso3166OneAlpha2EuCountries["Finland"] = "FI";
    EIso3166OneAlpha2EuCountries["France"] = "FR";
    EIso3166OneAlpha2EuCountries["Germany"] = "DE";
    EIso3166OneAlpha2EuCountries["Greece"] = "GR";
    EIso3166OneAlpha2EuCountries["Hungary"] = "HU";
    EIso3166OneAlpha2EuCountries["Ireland"] = "IE";
    EIso3166OneAlpha2EuCountries["Italy"] = "IT";
    EIso3166OneAlpha2EuCountries["Latvia"] = "LV";
    EIso3166OneAlpha2EuCountries["Lithuania"] = "LT";
    EIso3166OneAlpha2EuCountries["Luxembourg"] = "LU";
    EIso3166OneAlpha2EuCountries["Malta"] = "MT";
    EIso3166OneAlpha2EuCountries["Netherlands"] = "NL";
    EIso3166OneAlpha2EuCountries["Poland"] = "PL";
    EIso3166OneAlpha2EuCountries["Portugal"] = "PT";
    EIso3166OneAlpha2EuCountries["Romania"] = "RO";
    EIso3166OneAlpha2EuCountries["Slovakia"] = "SK";
    EIso3166OneAlpha2EuCountries["Slovenia"] = "SI";
    EIso3166OneAlpha2EuCountries["Spain"] = "ES";
    EIso3166OneAlpha2EuCountries["Sweden"] = "SE";
})(EIso3166OneAlpha2EuCountries || (EIso3166OneAlpha2EuCountries = {}));
var EIso3166OneAlpha2EeaCountries;
(function(EIso3166OneAlpha2EeaCountries) {
    EIso3166OneAlpha2EeaCountries["Austria"] = "AT";
    EIso3166OneAlpha2EeaCountries["Belgium"] = "BE";
    EIso3166OneAlpha2EeaCountries["Bulgaria"] = "BG";
    EIso3166OneAlpha2EeaCountries["Croatia"] = "HR";
    EIso3166OneAlpha2EeaCountries["Cyprus"] = "CY";
    EIso3166OneAlpha2EeaCountries["CzechRepublic"] = "CZ";
    EIso3166OneAlpha2EeaCountries["Denmark"] = "DK";
    EIso3166OneAlpha2EeaCountries["Estonia"] = "EE";
    EIso3166OneAlpha2EeaCountries["Finland"] = "FI";
    EIso3166OneAlpha2EeaCountries["France"] = "FR";
    EIso3166OneAlpha2EeaCountries["Germany"] = "DE";
    EIso3166OneAlpha2EeaCountries["Greece"] = "GR";
    EIso3166OneAlpha2EeaCountries["Hungary"] = "HU";
    EIso3166OneAlpha2EeaCountries["Iceland"] = "IS";
    EIso3166OneAlpha2EeaCountries["Ireland"] = "IE";
    EIso3166OneAlpha2EeaCountries["Italy"] = "IT";
    EIso3166OneAlpha2EeaCountries["Latvia"] = "LV";
    EIso3166OneAlpha2EeaCountries["Liechtenstein"] = "LI";
    EIso3166OneAlpha2EeaCountries["Lithuania"] = "LT";
    EIso3166OneAlpha2EeaCountries["Luxembourg"] = "LU";
    EIso3166OneAlpha2EeaCountries["Malta"] = "MT";
    EIso3166OneAlpha2EeaCountries["Netherlands"] = "NL";
    EIso3166OneAlpha2EeaCountries["Norway"] = "NO";
    EIso3166OneAlpha2EeaCountries["Poland"] = "PL";
    EIso3166OneAlpha2EeaCountries["Portugal"] = "PT";
    EIso3166OneAlpha2EeaCountries["Romania"] = "RO";
    EIso3166OneAlpha2EeaCountries["Slovakia"] = "SK";
    EIso3166OneAlpha2EeaCountries["Slovenia"] = "SI";
    EIso3166OneAlpha2EeaCountries["Spain"] = "ES";
    EIso3166OneAlpha2EeaCountries["Sweden"] = "SE";
})(EIso3166OneAlpha2EeaCountries || (EIso3166OneAlpha2EeaCountries = {}));
var EIso3166OneAlpha2AdequacyCountries;
(function(EIso3166OneAlpha2AdequacyCountries) {
    EIso3166OneAlpha2AdequacyCountries["Andorra"] = "AD";
    EIso3166OneAlpha2AdequacyCountries["Argentina"] = "AR";
    EIso3166OneAlpha2AdequacyCountries["Canada"] = "CA";
    EIso3166OneAlpha2AdequacyCountries["FaroeIslands"] = "FO";
    EIso3166OneAlpha2AdequacyCountries["Guernsey"] = "GG";
    EIso3166OneAlpha2AdequacyCountries["Israel"] = "IL";
    EIso3166OneAlpha2AdequacyCountries["IsleOfMan"] = "IM";
    EIso3166OneAlpha2AdequacyCountries["Japan"] = "JP";
    EIso3166OneAlpha2AdequacyCountries["Jersey"] = "JE";
    EIso3166OneAlpha2AdequacyCountries["NewZealand"] = "NZ";
    EIso3166OneAlpha2AdequacyCountries["Korea"] = "KR";
    EIso3166OneAlpha2AdequacyCountries["Switzerland"] = "CH";
    EIso3166OneAlpha2AdequacyCountries["UnitedKingdom"] = "GB";
    EIso3166OneAlpha2AdequacyCountries["Uruguay"] = "UY";
    EIso3166OneAlpha2AdequacyCountries["UnitedStates"] = "US";
})(EIso3166OneAlpha2AdequacyCountries || (EIso3166OneAlpha2AdequacyCountries = {}));
var EIso3166OneAlpha2CloudflareCountries;
(function(EIso3166OneAlpha2CloudflareCountries) {
    EIso3166OneAlpha2CloudflareCountries["Afghanistan"] = "AF";
    EIso3166OneAlpha2CloudflareCountries["Albania"] = "AL";
    EIso3166OneAlpha2CloudflareCountries["Algeria"] = "DZ";
    EIso3166OneAlpha2CloudflareCountries["Andorra"] = "AD";
    EIso3166OneAlpha2CloudflareCountries["Angola"] = "AO";
    EIso3166OneAlpha2CloudflareCountries["AntiguaAndBarbuda"] = "AG";
    EIso3166OneAlpha2CloudflareCountries["Argentina"] = "AR";
    EIso3166OneAlpha2CloudflareCountries["Armenia"] = "AM";
    EIso3166OneAlpha2CloudflareCountries["Australia"] = "AU";
    EIso3166OneAlpha2CloudflareCountries["Austria"] = "AT";
    EIso3166OneAlpha2CloudflareCountries["Azerbaijan"] = "AZ";
    EIso3166OneAlpha2CloudflareCountries["Bahamas"] = "BS";
    EIso3166OneAlpha2CloudflareCountries["Bahrain"] = "BH";
    EIso3166OneAlpha2CloudflareCountries["Bangladesh"] = "BD";
    EIso3166OneAlpha2CloudflareCountries["Barbados"] = "BB";
    EIso3166OneAlpha2CloudflareCountries["Belarus"] = "BY";
    EIso3166OneAlpha2CloudflareCountries["Belgium"] = "BE";
    EIso3166OneAlpha2CloudflareCountries["Belize"] = "BZ";
    EIso3166OneAlpha2CloudflareCountries["Benin"] = "BJ";
    EIso3166OneAlpha2CloudflareCountries["Bhutan"] = "BT";
    EIso3166OneAlpha2CloudflareCountries["Bolivia"] = "BO";
    EIso3166OneAlpha2CloudflareCountries["BosniaAndHerzegovina"] = "BA";
    EIso3166OneAlpha2CloudflareCountries["Botswana"] = "BW";
    EIso3166OneAlpha2CloudflareCountries["Brazil"] = "BR";
    EIso3166OneAlpha2CloudflareCountries["BruneiDarussalam"] = "BN";
    EIso3166OneAlpha2CloudflareCountries["Bulgaria"] = "BG";
    EIso3166OneAlpha2CloudflareCountries["BurkinaFaso"] = "BF";
    EIso3166OneAlpha2CloudflareCountries["Burundi"] = "BI";
    EIso3166OneAlpha2CloudflareCountries["Cambodia"] = "KH";
    EIso3166OneAlpha2CloudflareCountries["Cameroon"] = "CM";
    EIso3166OneAlpha2CloudflareCountries["Canada"] = "CA";
    EIso3166OneAlpha2CloudflareCountries["CapeVerde"] = "CV";
    EIso3166OneAlpha2CloudflareCountries["CentralAfricanRepublic"] = "CF";
    EIso3166OneAlpha2CloudflareCountries["Chad"] = "TD";
    EIso3166OneAlpha2CloudflareCountries["Chile"] = "CL";
    EIso3166OneAlpha2CloudflareCountries["China"] = "CN";
    EIso3166OneAlpha2CloudflareCountries["Colombia"] = "CO";
    EIso3166OneAlpha2CloudflareCountries["Comoros"] = "KM";
    EIso3166OneAlpha2CloudflareCountries["Congo"] = "CG";
    EIso3166OneAlpha2CloudflareCountries["CongoDemocraticRepublic"] = "CD";
    EIso3166OneAlpha2CloudflareCountries["CostaRica"] = "CR";
    EIso3166OneAlpha2CloudflareCountries["Croatia"] = "HR";
    EIso3166OneAlpha2CloudflareCountries["Cuba"] = "CU";
    EIso3166OneAlpha2CloudflareCountries["Cyprus"] = "CY";
    EIso3166OneAlpha2CloudflareCountries["CzechRepublic"] = "CZ";
    EIso3166OneAlpha2CloudflareCountries["Denmark"] = "DK";
    EIso3166OneAlpha2CloudflareCountries["Djibouti"] = "DJ";
    EIso3166OneAlpha2CloudflareCountries["Dominica"] = "DM";
    EIso3166OneAlpha2CloudflareCountries["DominicanRepublic"] = "DO";
    EIso3166OneAlpha2CloudflareCountries["Ecuador"] = "EC";
    EIso3166OneAlpha2CloudflareCountries["Egypt"] = "EG";
    EIso3166OneAlpha2CloudflareCountries["ElSalvador"] = "SV";
    EIso3166OneAlpha2CloudflareCountries["EquatorialGuinea"] = "GQ";
    EIso3166OneAlpha2CloudflareCountries["Eritrea"] = "ER";
    EIso3166OneAlpha2CloudflareCountries["Estonia"] = "EE";
    EIso3166OneAlpha2CloudflareCountries["Ethiopia"] = "ET";
    EIso3166OneAlpha2CloudflareCountries["Fiji"] = "FJ";
    EIso3166OneAlpha2CloudflareCountries["Finland"] = "FI";
    EIso3166OneAlpha2CloudflareCountries["France"] = "FR";
    EIso3166OneAlpha2CloudflareCountries["Gabon"] = "GA";
    EIso3166OneAlpha2CloudflareCountries["Gambia"] = "GM";
    EIso3166OneAlpha2CloudflareCountries["Georgia"] = "GE";
    EIso3166OneAlpha2CloudflareCountries["Germany"] = "DE";
    EIso3166OneAlpha2CloudflareCountries["Ghana"] = "GH";
    EIso3166OneAlpha2CloudflareCountries["Greece"] = "GR";
    EIso3166OneAlpha2CloudflareCountries["Grenada"] = "GD";
    EIso3166OneAlpha2CloudflareCountries["Guatemala"] = "GT";
    EIso3166OneAlpha2CloudflareCountries["Guinea"] = "GN";
    EIso3166OneAlpha2CloudflareCountries["GuineaBissau"] = "GW";
    EIso3166OneAlpha2CloudflareCountries["Guyana"] = "GY";
    EIso3166OneAlpha2CloudflareCountries["Haiti"] = "HT";
    EIso3166OneAlpha2CloudflareCountries["Honduras"] = "HN";
    EIso3166OneAlpha2CloudflareCountries["Hungary"] = "HU";
    EIso3166OneAlpha2CloudflareCountries["Iceland"] = "IS";
    EIso3166OneAlpha2CloudflareCountries["India"] = "IN";
    EIso3166OneAlpha2CloudflareCountries["Indonesia"] = "ID";
    EIso3166OneAlpha2CloudflareCountries["IranIslamicRepublicOf"] = "IR";
    EIso3166OneAlpha2CloudflareCountries["Iraq"] = "IQ";
    EIso3166OneAlpha2CloudflareCountries["Ireland"] = "IE";
    EIso3166OneAlpha2CloudflareCountries["Israel"] = "IL";
    EIso3166OneAlpha2CloudflareCountries["Italy"] = "IT";
    EIso3166OneAlpha2CloudflareCountries["Jamaica"] = "JM";
    EIso3166OneAlpha2CloudflareCountries["Japan"] = "JP";
    EIso3166OneAlpha2CloudflareCountries["Jordan"] = "JO";
    EIso3166OneAlpha2CloudflareCountries["Kazakhstan"] = "KZ";
    EIso3166OneAlpha2CloudflareCountries["Kenya"] = "KE";
    EIso3166OneAlpha2CloudflareCountries["Kiribati"] = "KI";
    EIso3166OneAlpha2CloudflareCountries["Korea"] = "KR";
    EIso3166OneAlpha2CloudflareCountries["NorthKorea"] = "KP";
    EIso3166OneAlpha2CloudflareCountries["Kuwait"] = "KW";
    EIso3166OneAlpha2CloudflareCountries["Kyrgyzstan"] = "KG";
    EIso3166OneAlpha2CloudflareCountries["LaoPeoplesDemocraticRepublic"] = "LA";
    EIso3166OneAlpha2CloudflareCountries["Latvia"] = "LV";
    EIso3166OneAlpha2CloudflareCountries["Lebanon"] = "LB";
    EIso3166OneAlpha2CloudflareCountries["Lesotho"] = "LS";
    EIso3166OneAlpha2CloudflareCountries["Liberia"] = "LR";
    EIso3166OneAlpha2CloudflareCountries["LibyanArabJamahiriya"] = "LY";
    EIso3166OneAlpha2CloudflareCountries["Liechtenstein"] = "LI";
    EIso3166OneAlpha2CloudflareCountries["Lithuania"] = "LT";
    EIso3166OneAlpha2CloudflareCountries["Luxembourg"] = "LU";
    EIso3166OneAlpha2CloudflareCountries["Madagascar"] = "MG";
    EIso3166OneAlpha2CloudflareCountries["Malawi"] = "MW";
    EIso3166OneAlpha2CloudflareCountries["Malaysia"] = "MY";
    EIso3166OneAlpha2CloudflareCountries["Maldives"] = "MV";
    EIso3166OneAlpha2CloudflareCountries["Mali"] = "ML";
    EIso3166OneAlpha2CloudflareCountries["Malta"] = "MT";
    EIso3166OneAlpha2CloudflareCountries["MarshallIslands"] = "MH";
    EIso3166OneAlpha2CloudflareCountries["Mauritania"] = "MR";
    EIso3166OneAlpha2CloudflareCountries["Mauritius"] = "MU";
    EIso3166OneAlpha2CloudflareCountries["Macedonia"] = "MK";
    EIso3166OneAlpha2CloudflareCountries["Mexico"] = "MX";
    EIso3166OneAlpha2CloudflareCountries["MicronesiaFederatedStatesOf"] = "FM";
    EIso3166OneAlpha2CloudflareCountries["Moldova"] = "MD";
    EIso3166OneAlpha2CloudflareCountries["Monaco"] = "MC";
    EIso3166OneAlpha2CloudflareCountries["Mongolia"] = "MN";
    EIso3166OneAlpha2CloudflareCountries["Montenegro"] = "ME";
    EIso3166OneAlpha2CloudflareCountries["Morocco"] = "MA";
    EIso3166OneAlpha2CloudflareCountries["Mozambique"] = "MZ";
    EIso3166OneAlpha2CloudflareCountries["Myanmar"] = "MM";
    EIso3166OneAlpha2CloudflareCountries["Namibia"] = "NA";
    EIso3166OneAlpha2CloudflareCountries["Nauru"] = "NR";
    EIso3166OneAlpha2CloudflareCountries["Nepal"] = "NP";
    EIso3166OneAlpha2CloudflareCountries["Netherlands"] = "NL";
    EIso3166OneAlpha2CloudflareCountries["NewZealand"] = "NZ";
    EIso3166OneAlpha2CloudflareCountries["Nicaragua"] = "NI";
    EIso3166OneAlpha2CloudflareCountries["Niger"] = "NE";
    EIso3166OneAlpha2CloudflareCountries["Nigeria"] = "NG";
    EIso3166OneAlpha2CloudflareCountries["Norway"] = "NO";
    EIso3166OneAlpha2CloudflareCountries["Oman"] = "OM";
    EIso3166OneAlpha2CloudflareCountries["Pakistan"] = "PK";
    EIso3166OneAlpha2CloudflareCountries["Palau"] = "PW";
    EIso3166OneAlpha2CloudflareCountries["Panama"] = "PA";
    EIso3166OneAlpha2CloudflareCountries["PapuaNewGuinea"] = "PG";
    EIso3166OneAlpha2CloudflareCountries["Paraguay"] = "PY";
    EIso3166OneAlpha2CloudflareCountries["Peru"] = "PE";
    EIso3166OneAlpha2CloudflareCountries["Philippines"] = "PH";
    EIso3166OneAlpha2CloudflareCountries["Poland"] = "PL";
    EIso3166OneAlpha2CloudflareCountries["Portugal"] = "PT";
    EIso3166OneAlpha2CloudflareCountries["Qatar"] = "QA";
    EIso3166OneAlpha2CloudflareCountries["Romania"] = "RO";
    EIso3166OneAlpha2CloudflareCountries["RussianFederation"] = "RU";
    EIso3166OneAlpha2CloudflareCountries["Rwanda"] = "RW";
    EIso3166OneAlpha2CloudflareCountries["SaintKittsAndNevis"] = "KN";
    EIso3166OneAlpha2CloudflareCountries["SaintLucia"] = "LC";
    EIso3166OneAlpha2CloudflareCountries["Samoa"] = "WS";
    EIso3166OneAlpha2CloudflareCountries["SanMarino"] = "SM";
    EIso3166OneAlpha2CloudflareCountries["SaoTomeAndPrincipe"] = "ST";
    EIso3166OneAlpha2CloudflareCountries["SaudiArabia"] = "SA";
    EIso3166OneAlpha2CloudflareCountries["Senegal"] = "SN";
    EIso3166OneAlpha2CloudflareCountries["Serbia"] = "RS";
    EIso3166OneAlpha2CloudflareCountries["Seychelles"] = "SC";
    EIso3166OneAlpha2CloudflareCountries["SierraLeone"] = "SL";
    EIso3166OneAlpha2CloudflareCountries["Singapore"] = "SG";
    EIso3166OneAlpha2CloudflareCountries["Slovakia"] = "SK";
    EIso3166OneAlpha2CloudflareCountries["Slovenia"] = "SI";
    EIso3166OneAlpha2CloudflareCountries["SolomonIslands"] = "SB";
    EIso3166OneAlpha2CloudflareCountries["Somalia"] = "SO";
    EIso3166OneAlpha2CloudflareCountries["SouthAfrica"] = "ZA";
    EIso3166OneAlpha2CloudflareCountries["Spain"] = "ES";
    EIso3166OneAlpha2CloudflareCountries["SriLanka"] = "LK";
    EIso3166OneAlpha2CloudflareCountries["Sudan"] = "SD";
    EIso3166OneAlpha2CloudflareCountries["Suriname"] = "SR";
    EIso3166OneAlpha2CloudflareCountries["Swaziland"] = "SZ";
    EIso3166OneAlpha2CloudflareCountries["Sweden"] = "SE";
    EIso3166OneAlpha2CloudflareCountries["Switzerland"] = "CH";
    EIso3166OneAlpha2CloudflareCountries["SyrianArabRepublic"] = "SY";
    EIso3166OneAlpha2CloudflareCountries["Taiwan"] = "TW";
    EIso3166OneAlpha2CloudflareCountries["Tajikistan"] = "TJ";
    EIso3166OneAlpha2CloudflareCountries["Tanzania"] = "TZ";
    EIso3166OneAlpha2CloudflareCountries["Thailand"] = "TH";
    EIso3166OneAlpha2CloudflareCountries["TimorLeste"] = "TL";
    EIso3166OneAlpha2CloudflareCountries["Togo"] = "TG";
    EIso3166OneAlpha2CloudflareCountries["Tonga"] = "TO";
    EIso3166OneAlpha2CloudflareCountries["TrinidadAndTobago"] = "TT";
    EIso3166OneAlpha2CloudflareCountries["Tunisia"] = "TN";
    EIso3166OneAlpha2CloudflareCountries["Turkey"] = "TR";
    EIso3166OneAlpha2CloudflareCountries["Turkmenistan"] = "TM";
    EIso3166OneAlpha2CloudflareCountries["Tuvalu"] = "TV";
    EIso3166OneAlpha2CloudflareCountries["Uganda"] = "UG";
    EIso3166OneAlpha2CloudflareCountries["Ukraine"] = "UA";
    EIso3166OneAlpha2CloudflareCountries["UnitedArabEmirates"] = "AE";
    EIso3166OneAlpha2CloudflareCountries["UnitedKingdom"] = "GB";
    EIso3166OneAlpha2CloudflareCountries["UnitedStates"] = "US";
    EIso3166OneAlpha2CloudflareCountries["Uruguay"] = "UY";
    EIso3166OneAlpha2CloudflareCountries["Uzbekistan"] = "UZ";
    EIso3166OneAlpha2CloudflareCountries["Vanuatu"] = "VU";
    EIso3166OneAlpha2CloudflareCountries["HolySeeVaticanCityState"] = "VA";
    EIso3166OneAlpha2CloudflareCountries["Venezuela"] = "VE";
    EIso3166OneAlpha2CloudflareCountries["Vietnam"] = "VN";
    EIso3166OneAlpha2CloudflareCountries["Yemen"] = "YE";
    EIso3166OneAlpha2CloudflareCountries["Zambia"] = "ZM";
    EIso3166OneAlpha2CloudflareCountries["Zimbabwe"] = "ZW";
})(EIso3166OneAlpha2CloudflareCountries || (EIso3166OneAlpha2CloudflareCountries = {}));
var EIso3166OneAlpha2FastlyCountries;
(function(EIso3166OneAlpha2FastlyCountries) {
    EIso3166OneAlpha2FastlyCountries["Argentina"] = "AR";
    EIso3166OneAlpha2FastlyCountries["Australia"] = "AU";
    EIso3166OneAlpha2FastlyCountries["Austria"] = "AT";
    EIso3166OneAlpha2FastlyCountries["Belgium"] = "BE";
    EIso3166OneAlpha2FastlyCountries["Brazil"] = "BR";
    EIso3166OneAlpha2FastlyCountries["Bulgaria"] = "BG";
    EIso3166OneAlpha2FastlyCountries["Canada"] = "CA";
    EIso3166OneAlpha2FastlyCountries["Chile"] = "CL";
    EIso3166OneAlpha2FastlyCountries["HongKong"] = "HK";
    EIso3166OneAlpha2FastlyCountries["Colombia"] = "CO";
    EIso3166OneAlpha2FastlyCountries["Denmark"] = "DK";
    EIso3166OneAlpha2FastlyCountries["Finland"] = "FI";
    EIso3166OneAlpha2FastlyCountries["France"] = "FR";
    EIso3166OneAlpha2FastlyCountries["Germany"] = "DE";
    EIso3166OneAlpha2FastlyCountries["Ghana"] = "GH";
    EIso3166OneAlpha2FastlyCountries["India"] = "IN";
    EIso3166OneAlpha2FastlyCountries["Ireland"] = "IE";
    EIso3166OneAlpha2FastlyCountries["Italy"] = "IT";
    EIso3166OneAlpha2FastlyCountries["Japan"] = "JP";
    EIso3166OneAlpha2FastlyCountries["Kenya"] = "KE";
    EIso3166OneAlpha2FastlyCountries["Korea"] = "KR";
    EIso3166OneAlpha2FastlyCountries["Malaysia"] = "MY";
    EIso3166OneAlpha2FastlyCountries["Netherlands"] = "NL";
    EIso3166OneAlpha2FastlyCountries["NewZealand"] = "NZ";
    EIso3166OneAlpha2FastlyCountries["Norway"] = "NO";
    EIso3166OneAlpha2FastlyCountries["Peru"] = "PE";
    EIso3166OneAlpha2FastlyCountries["Philippines"] = "PH";
    EIso3166OneAlpha2FastlyCountries["Portugal"] = "PT";
    EIso3166OneAlpha2FastlyCountries["Singapore"] = "SG";
    EIso3166OneAlpha2FastlyCountries["SouthAfrica"] = "ZA";
    EIso3166OneAlpha2FastlyCountries["Spain"] = "ES";
    EIso3166OneAlpha2FastlyCountries["Sweden"] = "SE";
    EIso3166OneAlpha2FastlyCountries["Thailand"] = "TH";
    EIso3166OneAlpha2FastlyCountries["UnitedArabEmirates"] = "AE";
    EIso3166OneAlpha2FastlyCountries["UnitedKingdom"] = "GB";
    EIso3166OneAlpha2FastlyCountries["UnitedStates"] = "US";
})(EIso3166OneAlpha2FastlyCountries || (EIso3166OneAlpha2FastlyCountries = {}));
var EIso3166OneAlpha2AkamaiCountries;
(function(EIso3166OneAlpha2AkamaiCountries) {
    EIso3166OneAlpha2AkamaiCountries["Albania"] = "AL";
    EIso3166OneAlpha2AkamaiCountries["Angola"] = "AO";
    EIso3166OneAlpha2AkamaiCountries["Argentina"] = "AR";
    EIso3166OneAlpha2AkamaiCountries["Armenia"] = "AM";
    EIso3166OneAlpha2AkamaiCountries["Australia"] = "AU";
    EIso3166OneAlpha2AkamaiCountries["Austria"] = "AT";
    EIso3166OneAlpha2AkamaiCountries["Azerbaijan"] = "AZ";
    EIso3166OneAlpha2AkamaiCountries["Bahamas"] = "BS";
    EIso3166OneAlpha2AkamaiCountries["Bahrain"] = "BH";
    EIso3166OneAlpha2AkamaiCountries["Bangladesh"] = "BD";
    EIso3166OneAlpha2AkamaiCountries["Barbados"] = "BB";
    EIso3166OneAlpha2AkamaiCountries["Belarus"] = "BY";
    EIso3166OneAlpha2AkamaiCountries["Belgium"] = "BE";
    EIso3166OneAlpha2AkamaiCountries["Belize"] = "BZ";
    EIso3166OneAlpha2AkamaiCountries["Bhutan"] = "BT";
    EIso3166OneAlpha2AkamaiCountries["Bolivia"] = "BO";
    EIso3166OneAlpha2AkamaiCountries["Botswana"] = "BW";
    EIso3166OneAlpha2AkamaiCountries["Brazil"] = "BR";
    EIso3166OneAlpha2AkamaiCountries["BruneiDarussalam"] = "BN";
    EIso3166OneAlpha2AkamaiCountries["Bulgaria"] = "BG";
    EIso3166OneAlpha2AkamaiCountries["Burundi"] = "BI";
    EIso3166OneAlpha2AkamaiCountries["Cambodia"] = "KH";
    EIso3166OneAlpha2AkamaiCountries["Cameroon"] = "CM";
    EIso3166OneAlpha2AkamaiCountries["Canada"] = "CA";
    EIso3166OneAlpha2AkamaiCountries["Chile"] = "CL";
    EIso3166OneAlpha2AkamaiCountries["Colombia"] = "CO";
    EIso3166OneAlpha2AkamaiCountries["CostaRica"] = "CR";
    EIso3166OneAlpha2AkamaiCountries["Croatia"] = "HR";
    EIso3166OneAlpha2AkamaiCountries["Cyprus"] = "CY";
    EIso3166OneAlpha2AkamaiCountries["CzechRepublic"] = "CZ";
    EIso3166OneAlpha2AkamaiCountries["Denmark"] = "DK";
    EIso3166OneAlpha2AkamaiCountries["DominicanRepublic"] = "DO";
    EIso3166OneAlpha2AkamaiCountries["Ecuador"] = "EC";
    EIso3166OneAlpha2AkamaiCountries["Egypt"] = "EG";
    EIso3166OneAlpha2AkamaiCountries["ElSalvador"] = "SV";
    EIso3166OneAlpha2AkamaiCountries["Estonia"] = "EE";
    EIso3166OneAlpha2AkamaiCountries["Finland"] = "FI";
    EIso3166OneAlpha2AkamaiCountries["France"] = "FR";
    EIso3166OneAlpha2AkamaiCountries["Georgia"] = "GE";
    EIso3166OneAlpha2AkamaiCountries["Germany"] = "DE";
    EIso3166OneAlpha2AkamaiCountries["Ghana"] = "GH";
    EIso3166OneAlpha2AkamaiCountries["Greece"] = "GR";
    EIso3166OneAlpha2AkamaiCountries["Grenada"] = "GD";
    EIso3166OneAlpha2AkamaiCountries["Guatemala"] = "GT";
    EIso3166OneAlpha2AkamaiCountries["Guinea"] = "GN";
    EIso3166OneAlpha2AkamaiCountries["Haiti"] = "HT";
    EIso3166OneAlpha2AkamaiCountries["Honduras"] = "HN";
    EIso3166OneAlpha2AkamaiCountries["HongKong"] = "HK";
    EIso3166OneAlpha2AkamaiCountries["Hungary"] = "HU";
    EIso3166OneAlpha2AkamaiCountries["Iceland"] = "IS";
    EIso3166OneAlpha2AkamaiCountries["India"] = "IN";
    EIso3166OneAlpha2AkamaiCountries["Indonesia"] = "ID";
    EIso3166OneAlpha2AkamaiCountries["Iraq"] = "IQ";
    EIso3166OneAlpha2AkamaiCountries["Ireland"] = "IE";
    EIso3166OneAlpha2AkamaiCountries["Israel"] = "IL";
    EIso3166OneAlpha2AkamaiCountries["Italy"] = "IT";
    EIso3166OneAlpha2AkamaiCountries["Jamaica"] = "JM";
    EIso3166OneAlpha2AkamaiCountries["Japan"] = "JP";
    EIso3166OneAlpha2AkamaiCountries["Jordan"] = "JO";
    EIso3166OneAlpha2AkamaiCountries["Kazakhstan"] = "KZ";
    EIso3166OneAlpha2AkamaiCountries["Kenya"] = "KE";
    EIso3166OneAlpha2AkamaiCountries["Kuwait"] = "KW";
    EIso3166OneAlpha2AkamaiCountries["Kyrgyzstan"] = "KG";
    EIso3166OneAlpha2AkamaiCountries["Laos"] = "LA";
    EIso3166OneAlpha2AkamaiCountries["Latvia"] = "LV";
    EIso3166OneAlpha2AkamaiCountries["Lebanon"] = "LB";
    EIso3166OneAlpha2AkamaiCountries["Lithuania"] = "LT";
    EIso3166OneAlpha2AkamaiCountries["Luxembourg"] = "LU";
    EIso3166OneAlpha2AkamaiCountries["Macao"] = "MO";
    EIso3166OneAlpha2AkamaiCountries["Macedonia"] = "MK";
    EIso3166OneAlpha2AkamaiCountries["Madagascar"] = "MG";
    EIso3166OneAlpha2AkamaiCountries["Malaysia"] = "MY";
    EIso3166OneAlpha2AkamaiCountries["Malta"] = "MT";
    EIso3166OneAlpha2AkamaiCountries["Mauritius"] = "MU";
    EIso3166OneAlpha2AkamaiCountries["Mexico"] = "MX";
    EIso3166OneAlpha2AkamaiCountries["Moldova"] = "MD";
    EIso3166OneAlpha2AkamaiCountries["Mongolia"] = "MN";
    EIso3166OneAlpha2AkamaiCountries["Morocco"] = "MA";
    EIso3166OneAlpha2AkamaiCountries["Mozambique"] = "MZ";
    EIso3166OneAlpha2AkamaiCountries["Myanmar"] = "MM";
    EIso3166OneAlpha2AkamaiCountries["Namibia"] = "NA";
    EIso3166OneAlpha2AkamaiCountries["Nepal"] = "NP";
    EIso3166OneAlpha2AkamaiCountries["Netherlands"] = "NL";
    EIso3166OneAlpha2AkamaiCountries["NewZealand"] = "NZ";
    EIso3166OneAlpha2AkamaiCountries["Nicaragua"] = "NI";
    EIso3166OneAlpha2AkamaiCountries["Nigeria"] = "NG";
    EIso3166OneAlpha2AkamaiCountries["Norway"] = "NO";
    EIso3166OneAlpha2AkamaiCountries["Oman"] = "OM";
    EIso3166OneAlpha2AkamaiCountries["Pakistan"] = "PK";
    EIso3166OneAlpha2AkamaiCountries["Panama"] = "PA";
    EIso3166OneAlpha2AkamaiCountries["Paraguay"] = "PY";
    EIso3166OneAlpha2AkamaiCountries["Peru"] = "PE";
    EIso3166OneAlpha2AkamaiCountries["Philippines"] = "PH";
    EIso3166OneAlpha2AkamaiCountries["Poland"] = "PL";
    EIso3166OneAlpha2AkamaiCountries["Portugal"] = "PT";
    EIso3166OneAlpha2AkamaiCountries["Qatar"] = "QA";
    EIso3166OneAlpha2AkamaiCountries["Romania"] = "RO";
    EIso3166OneAlpha2AkamaiCountries["RussianFederation"] = "RU";
    EIso3166OneAlpha2AkamaiCountries["Rwanda"] = "RW";
    EIso3166OneAlpha2AkamaiCountries["SaintLucia"] = "LC";
    EIso3166OneAlpha2AkamaiCountries["SaudiArabia"] = "SA";
    EIso3166OneAlpha2AkamaiCountries["Senegal"] = "SN";
    EIso3166OneAlpha2AkamaiCountries["Serbia"] = "RS";
    EIso3166OneAlpha2AkamaiCountries["Seychelles"] = "SC";
    EIso3166OneAlpha2AkamaiCountries["Singapore"] = "SG";
    EIso3166OneAlpha2AkamaiCountries["Slovakia"] = "SK";
    EIso3166OneAlpha2AkamaiCountries["Slovenia"] = "SI";
    EIso3166OneAlpha2AkamaiCountries["SouthAfrica"] = "ZA";
    EIso3166OneAlpha2AkamaiCountries["Korea"] = "KR";
    EIso3166OneAlpha2AkamaiCountries["Spain"] = "ES";
    EIso3166OneAlpha2AkamaiCountries["SriLanka"] = "LK";
    EIso3166OneAlpha2AkamaiCountries["Suriname"] = "SR";
    EIso3166OneAlpha2AkamaiCountries["Sweden"] = "SE";
    EIso3166OneAlpha2AkamaiCountries["Switzerland"] = "CH";
    EIso3166OneAlpha2AkamaiCountries["Taiwan"] = "TW";
    EIso3166OneAlpha2AkamaiCountries["Tanzania"] = "TZ";
    EIso3166OneAlpha2AkamaiCountries["Thailand"] = "TH";
    EIso3166OneAlpha2AkamaiCountries["Togo"] = "TG";
    EIso3166OneAlpha2AkamaiCountries["TrinidadAndTobago"] = "TT";
    EIso3166OneAlpha2AkamaiCountries["Tunisia"] = "TN";
    EIso3166OneAlpha2AkamaiCountries["Turkey"] = "TR";
    EIso3166OneAlpha2AkamaiCountries["Uganda"] = "UG";
    EIso3166OneAlpha2AkamaiCountries["Ukraine"] = "UA";
    EIso3166OneAlpha2AkamaiCountries["UnitedArabEmirates"] = "AE";
    EIso3166OneAlpha2AkamaiCountries["UnitedKingdom"] = "GB";
    EIso3166OneAlpha2AkamaiCountries["UnitedStates"] = "US";
    EIso3166OneAlpha2AkamaiCountries["Uruguay"] = "UY";
    EIso3166OneAlpha2AkamaiCountries["Venezuela"] = "VE";
    EIso3166OneAlpha2AkamaiCountries["Vietnam"] = "VN";
    EIso3166OneAlpha2AkamaiCountries["Zambia"] = "ZM";
})(EIso3166OneAlpha2AkamaiCountries || (EIso3166OneAlpha2AkamaiCountries = {}));
var EIso3166OneAlpha2AWSCountries;
(function(EIso3166OneAlpha2AWSCountries) {
    EIso3166OneAlpha2AWSCountries["Argentina"] = "AR";
    EIso3166OneAlpha2AWSCountries["Australia"] = "AU";
    EIso3166OneAlpha2AWSCountries["Bahrain"] = "BH";
    EIso3166OneAlpha2AWSCountries["Brazil"] = "BR";
    EIso3166OneAlpha2AWSCountries["Chile"] = "CL";
    EIso3166OneAlpha2AWSCountries["Colombia"] = "CO";
    EIso3166OneAlpha2AWSCountries["France"] = "FR";
    EIso3166OneAlpha2AWSCountries["Germany"] = "DE";
    EIso3166OneAlpha2AWSCountries["India"] = "IN";
    EIso3166OneAlpha2AWSCountries["Indonesia"] = "ID";
    EIso3166OneAlpha2AWSCountries["Ireland"] = "IE";
    EIso3166OneAlpha2AWSCountries["Italy"] = "IT";
    EIso3166OneAlpha2AWSCountries["Japan"] = "JP";
    EIso3166OneAlpha2AWSCountries["Korea"] = "KR";
    EIso3166OneAlpha2AWSCountries["Malaysia"] = "MY";
    EIso3166OneAlpha2AWSCountries["NewZealand"] = "NZ";
    EIso3166OneAlpha2AWSCountries["Philippines"] = "PH";
    EIso3166OneAlpha2AWSCountries["Singapore"] = "SG";
    EIso3166OneAlpha2AWSCountries["SouthAfrica"] = "ZA";
    EIso3166OneAlpha2AWSCountries["Spain"] = "ES";
    EIso3166OneAlpha2AWSCountries["Sweden"] = "SE";
    EIso3166OneAlpha2AWSCountries["Switzerland"] = "CH";
    EIso3166OneAlpha2AWSCountries["Taiwan"] = "TW";
    EIso3166OneAlpha2AWSCountries["Thailand"] = "TH";
    EIso3166OneAlpha2AWSCountries["UnitedArabEmirates"] = "AE";
    EIso3166OneAlpha2AWSCountries["UnitedKingdom"] = "GB";
    EIso3166OneAlpha2AWSCountries["UnitedStates"] = "US";
})(EIso3166OneAlpha2AWSCountries || (EIso3166OneAlpha2AWSCountries = {}));
var EIso3166OneAlpha2GoogleCloudCountries;
(function(EIso3166OneAlpha2GoogleCloudCountries) {
    EIso3166OneAlpha2GoogleCloudCountries["Australia"] = "AU";
    EIso3166OneAlpha2GoogleCloudCountries["Belgium"] = "BE";
    EIso3166OneAlpha2GoogleCloudCountries["Brazil"] = "BR";
    EIso3166OneAlpha2GoogleCloudCountries["Canada"] = "CA";
    EIso3166OneAlpha2GoogleCloudCountries["Chile"] = "CL";
    EIso3166OneAlpha2GoogleCloudCountries["Finland"] = "FI";
    EIso3166OneAlpha2GoogleCloudCountries["France"] = "FR";
    EIso3166OneAlpha2GoogleCloudCountries["Germany"] = "DE";
    EIso3166OneAlpha2GoogleCloudCountries["HongKong"] = "HK";
    EIso3166OneAlpha2GoogleCloudCountries["India"] = "IN";
    EIso3166OneAlpha2GoogleCloudCountries["Indonesia"] = "ID";
    EIso3166OneAlpha2GoogleCloudCountries["Israel"] = "IL";
    EIso3166OneAlpha2GoogleCloudCountries["Italy"] = "IT";
    EIso3166OneAlpha2GoogleCloudCountries["Japan"] = "JP";
    EIso3166OneAlpha2GoogleCloudCountries["Korea"] = "KR";
    EIso3166OneAlpha2GoogleCloudCountries["Netherlands"] = "NL";
    EIso3166OneAlpha2GoogleCloudCountries["Poland"] = "PL";
    EIso3166OneAlpha2GoogleCloudCountries["Qatar"] = "QA";
    EIso3166OneAlpha2GoogleCloudCountries["Singapore"] = "SG";
    EIso3166OneAlpha2GoogleCloudCountries["Spain"] = "ES";
    EIso3166OneAlpha2GoogleCloudCountries["Switzerland"] = "CH";
    EIso3166OneAlpha2GoogleCloudCountries["Taiwan"] = "TW";
    EIso3166OneAlpha2GoogleCloudCountries["UnitedKingdom"] = "GB";
    EIso3166OneAlpha2GoogleCloudCountries["UnitedStates"] = "US";
})(EIso3166OneAlpha2GoogleCloudCountries || (EIso3166OneAlpha2GoogleCloudCountries = {}));
var EIso3166OneAlpha2AutomatticCountries;
(function(EIso3166OneAlpha2AutomatticCountries) {
    EIso3166OneAlpha2AutomatticCountries["Afghanistan"] = "AF";
    EIso3166OneAlpha2AutomatticCountries["Albania"] = "AL";
    EIso3166OneAlpha2AutomatticCountries["Algeria"] = "DZ";
    EIso3166OneAlpha2AutomatticCountries["Andorra"] = "AD";
    EIso3166OneAlpha2AutomatticCountries["Angola"] = "AO";
    EIso3166OneAlpha2AutomatticCountries["Argentina"] = "AR";
    EIso3166OneAlpha2AutomatticCountries["Armenia"] = "AM";
    EIso3166OneAlpha2AutomatticCountries["Australia"] = "AU";
    EIso3166OneAlpha2AutomatticCountries["Austria"] = "AT";
    EIso3166OneAlpha2AutomatticCountries["Azerbaijan"] = "AZ";
    EIso3166OneAlpha2AutomatticCountries["Bahrain"] = "BH";
    EIso3166OneAlpha2AutomatticCountries["Bangladesh"] = "BD";
    EIso3166OneAlpha2AutomatticCountries["Barbados"] = "BB";
    EIso3166OneAlpha2AutomatticCountries["Belarus"] = "BY";
    EIso3166OneAlpha2AutomatticCountries["Belgium"] = "BE";
    EIso3166OneAlpha2AutomatticCountries["Benin"] = "BJ";
    EIso3166OneAlpha2AutomatticCountries["Bermuda"] = "BM";
    EIso3166OneAlpha2AutomatticCountries["Bhutan"] = "BT";
    EIso3166OneAlpha2AutomatticCountries["Bolivia"] = "BO";
    EIso3166OneAlpha2AutomatticCountries["BosniaAndHerzegovina"] = "BA";
    EIso3166OneAlpha2AutomatticCountries["Brazil"] = "BR";
    EIso3166OneAlpha2AutomatticCountries["VirginIslandsBritish"] = "VG";
    EIso3166OneAlpha2AutomatticCountries["BruneiDarussalam"] = "BN";
    EIso3166OneAlpha2AutomatticCountries["Bulgaria"] = "BG";
    EIso3166OneAlpha2AutomatticCountries["BurkinaFaso"] = "BF";
    EIso3166OneAlpha2AutomatticCountries["Cambodia"] = "KH";
    EIso3166OneAlpha2AutomatticCountries["Cameroon"] = "CM";
    EIso3166OneAlpha2AutomatticCountries["Canada"] = "CA";
    EIso3166OneAlpha2AutomatticCountries["CaymanIslands"] = "KY";
    EIso3166OneAlpha2AutomatticCountries["Chile"] = "CL";
    EIso3166OneAlpha2AutomatticCountries["China"] = "CN";
    EIso3166OneAlpha2AutomatticCountries["Colombia"] = "CO";
    EIso3166OneAlpha2AutomatticCountries["CostaRica"] = "CR";
    EIso3166OneAlpha2AutomatticCountries["Croatia"] = "HR";
    EIso3166OneAlpha2AutomatticCountries["Cuba"] = "CU";
    EIso3166OneAlpha2AutomatticCountries["Cyprus"] = "CY";
    EIso3166OneAlpha2AutomatticCountries["CzechRepublic"] = "CZ";
    EIso3166OneAlpha2AutomatticCountries["CoteDIvoire"] = "CI";
    EIso3166OneAlpha2AutomatticCountries["CongoDemocraticRepublic"] = "CD";
    EIso3166OneAlpha2AutomatticCountries["Denmark"] = "DK";
    EIso3166OneAlpha2AutomatticCountries["DominicanRepublic"] = "DO";
    EIso3166OneAlpha2AutomatticCountries["Ecuador"] = "EC";
    EIso3166OneAlpha2AutomatticCountries["Egypt"] = "EG";
    EIso3166OneAlpha2AutomatticCountries["Estonia"] = "EE";
    EIso3166OneAlpha2AutomatticCountries["Ethiopia"] = "ET";
    EIso3166OneAlpha2AutomatticCountries["Fiji"] = "FJ";
    EIso3166OneAlpha2AutomatticCountries["Finland"] = "FI";
    EIso3166OneAlpha2AutomatticCountries["France"] = "FR";
    EIso3166OneAlpha2AutomatticCountries["Gabon"] = "GA";
    EIso3166OneAlpha2AutomatticCountries["Georgia"] = "GE";
    EIso3166OneAlpha2AutomatticCountries["Germany"] = "DE";
    EIso3166OneAlpha2AutomatticCountries["Ghana"] = "GH";
    EIso3166OneAlpha2AutomatticCountries["Greece"] = "GR";
    EIso3166OneAlpha2AutomatticCountries["Guatemala"] = "GT";
    EIso3166OneAlpha2AutomatticCountries["Honduras"] = "HN";
    EIso3166OneAlpha2AutomatticCountries["Hungary"] = "HU";
    EIso3166OneAlpha2AutomatticCountries["Iceland"] = "IS";
    EIso3166OneAlpha2AutomatticCountries["India"] = "IN";
    EIso3166OneAlpha2AutomatticCountries["Indonesia"] = "ID";
    EIso3166OneAlpha2AutomatticCountries["Iraq"] = "IQ";
    EIso3166OneAlpha2AutomatticCountries["Ireland"] = "IE";
    EIso3166OneAlpha2AutomatticCountries["Israel"] = "IL";
    EIso3166OneAlpha2AutomatticCountries["Italy"] = "IT";
    EIso3166OneAlpha2AutomatticCountries["Jamaica"] = "JM";
    EIso3166OneAlpha2AutomatticCountries["Japan"] = "JP";
    EIso3166OneAlpha2AutomatticCountries["Jordan"] = "JO";
    EIso3166OneAlpha2AutomatticCountries["Kazakhstan"] = "KZ";
    EIso3166OneAlpha2AutomatticCountries["Kenya"] = "KE";
    EIso3166OneAlpha2AutomatticCountries["Kuwait"] = "KW";
    EIso3166OneAlpha2AutomatticCountries["Kyrgyzstan"] = "KG";
    EIso3166OneAlpha2AutomatticCountries["Latvia"] = "LV";
    EIso3166OneAlpha2AutomatticCountries["Lebanon"] = "LB";
    EIso3166OneAlpha2AutomatticCountries["Liechtenstein"] = "LI";
    EIso3166OneAlpha2AutomatticCountries["Lithuania"] = "LT";
    EIso3166OneAlpha2AutomatticCountries["Luxembourg"] = "LU";
    EIso3166OneAlpha2AutomatticCountries["Madagascar"] = "MG";
    EIso3166OneAlpha2AutomatticCountries["Malaysia"] = "MY";
    EIso3166OneAlpha2AutomatticCountries["Maldives"] = "MV";
    EIso3166OneAlpha2AutomatticCountries["Mali"] = "ML";
    EIso3166OneAlpha2AutomatticCountries["Malta"] = "MT";
    EIso3166OneAlpha2AutomatticCountries["Mauritius"] = "MU";
    EIso3166OneAlpha2AutomatticCountries["Mexico"] = "MX";
    EIso3166OneAlpha2AutomatticCountries["Moldova"] = "MD";
    EIso3166OneAlpha2AutomatticCountries["Mongolia"] = "MN";
    EIso3166OneAlpha2AutomatticCountries["Montenegro"] = "ME";
    EIso3166OneAlpha2AutomatticCountries["Morocco"] = "MA";
    EIso3166OneAlpha2AutomatticCountries["Mozambique"] = "MZ";
    EIso3166OneAlpha2AutomatticCountries["Myanmar"] = "MM";
    EIso3166OneAlpha2AutomatticCountries["Namibia"] = "NA";
    EIso3166OneAlpha2AutomatticCountries["Nepal"] = "NP";
    EIso3166OneAlpha2AutomatticCountries["Netherlands"] = "NL";
    EIso3166OneAlpha2AutomatticCountries["NewZealand"] = "NZ";
    EIso3166OneAlpha2AutomatticCountries["Nicaragua"] = "NI";
    EIso3166OneAlpha2AutomatticCountries["Niger"] = "NE";
    EIso3166OneAlpha2AutomatticCountries["Nigeria"] = "NG";
    EIso3166OneAlpha2AutomatticCountries["Macedonia"] = "MK";
    EIso3166OneAlpha2AutomatticCountries["Norway"] = "NO";
    EIso3166OneAlpha2AutomatticCountries["Oman"] = "OM";
    EIso3166OneAlpha2AutomatticCountries["Pakistan"] = "PK";
    EIso3166OneAlpha2AutomatticCountries["PalestinianTerritoryOccupied"] = "PS";
    EIso3166OneAlpha2AutomatticCountries["Panama"] = "PA";
    EIso3166OneAlpha2AutomatticCountries["Peru"] = "PE";
    EIso3166OneAlpha2AutomatticCountries["Philippines"] = "PH";
    EIso3166OneAlpha2AutomatticCountries["Poland"] = "PL";
    EIso3166OneAlpha2AutomatticCountries["Portugal"] = "PT";
    EIso3166OneAlpha2AutomatticCountries["Qatar"] = "QA";
    EIso3166OneAlpha2AutomatticCountries["Romania"] = "RO";
    EIso3166OneAlpha2AutomatticCountries["RussianFederation"] = "RU";
    EIso3166OneAlpha2AutomatticCountries["Rwanda"] = "RW";
    EIso3166OneAlpha2AutomatticCountries["SaintKittsAndNevis"] = "KN";
    EIso3166OneAlpha2AutomatticCountries["SaintVincentAndGrenadines"] = "VC";
    EIso3166OneAlpha2AutomatticCountries["Samoa"] = "WS";
    EIso3166OneAlpha2AutomatticCountries["SaudiArabia"] = "SA";
    EIso3166OneAlpha2AutomatticCountries["Senegal"] = "SN";
    EIso3166OneAlpha2AutomatticCountries["Serbia"] = "RS";
    EIso3166OneAlpha2AutomatticCountries["Singapore"] = "SG";
    EIso3166OneAlpha2AutomatticCountries["Slovakia"] = "SK";
    EIso3166OneAlpha2AutomatticCountries["Slovenia"] = "SI";
    EIso3166OneAlpha2AutomatticCountries["SolomonIslands"] = "SB";
    EIso3166OneAlpha2AutomatticCountries["Somalia"] = "SO";
    EIso3166OneAlpha2AutomatticCountries["SouthAfrica"] = "ZA";
    EIso3166OneAlpha2AutomatticCountries["Korea"] = "KR";
    EIso3166OneAlpha2AutomatticCountries["Spain"] = "ES";
    EIso3166OneAlpha2AutomatticCountries["SriLanka"] = "LK";
    EIso3166OneAlpha2AutomatticCountries["Sudan"] = "SD";
    EIso3166OneAlpha2AutomatticCountries["Sweden"] = "SE";
    EIso3166OneAlpha2AutomatticCountries["Switzerland"] = "CH";
    EIso3166OneAlpha2AutomatticCountries["SyrianArabRepublic"] = "SY";
    EIso3166OneAlpha2AutomatticCountries["SaoTomeAndPrincipe"] = "ST";
    EIso3166OneAlpha2AutomatticCountries["Taiwan"] = "TW";
    EIso3166OneAlpha2AutomatticCountries["Tanzania"] = "TZ";
    EIso3166OneAlpha2AutomatticCountries["Thailand"] = "TH";
    EIso3166OneAlpha2AutomatticCountries["Gambia"] = "GM";
    EIso3166OneAlpha2AutomatticCountries["Togo"] = "TG";
    EIso3166OneAlpha2AutomatticCountries["Tunisia"] = "TN";
    EIso3166OneAlpha2AutomatticCountries["Turkey"] = "TR";
    EIso3166OneAlpha2AutomatticCountries["TurksAndCaicosIslands"] = "TC";
    EIso3166OneAlpha2AutomatticCountries["Uganda"] = "UG";
    EIso3166OneAlpha2AutomatticCountries["Ukraine"] = "UA";
    EIso3166OneAlpha2AutomatticCountries["UnitedArabEmirates"] = "AE";
    EIso3166OneAlpha2AutomatticCountries["UnitedKingdom"] = "GB";
    EIso3166OneAlpha2AutomatticCountries["UnitedStates"] = "US";
    EIso3166OneAlpha2AutomatticCountries["Uruguay"] = "UY";
    EIso3166OneAlpha2AutomatticCountries["Uzbekistan"] = "UZ";
    EIso3166OneAlpha2AutomatticCountries["Vanuatu"] = "VU";
    EIso3166OneAlpha2AutomatticCountries["Venezuela"] = "VE";
    EIso3166OneAlpha2AutomatticCountries["Vietnam"] = "VN";
    EIso3166OneAlpha2AutomatticCountries["Zambia"] = "ZM";
    EIso3166OneAlpha2AutomatticCountries["Zimbabwe"] = "ZW";
})(EIso3166OneAlpha2AutomatticCountries || (EIso3166OneAlpha2AutomatticCountries = {}));
var EIso3166OneAlpha2BunnyNetCountries;
(function(EIso3166OneAlpha2BunnyNetCountries) {
    EIso3166OneAlpha2BunnyNetCountries["Netherlands"] = "NL";
    EIso3166OneAlpha2BunnyNetCountries["Greece"] = "GR";
    EIso3166OneAlpha2BunnyNetCountries["Serbia"] = "RS";
    EIso3166OneAlpha2BunnyNetCountries["Germany"] = "DE";
    EIso3166OneAlpha2BunnyNetCountries["Belgium"] = "BE";
    EIso3166OneAlpha2BunnyNetCountries["Hungary"] = "HU";
    EIso3166OneAlpha2BunnyNetCountries["Romania"] = "RO";
    EIso3166OneAlpha2BunnyNetCountries["Moldova"] = "MD";
    EIso3166OneAlpha2BunnyNetCountries["Denmark"] = "DK";
    EIso3166OneAlpha2BunnyNetCountries["Ireland"] = "IE";
    EIso3166OneAlpha2BunnyNetCountries["Finland"] = "FI";
    EIso3166OneAlpha2BunnyNetCountries["Iceland"] = "IS";
    EIso3166OneAlpha2BunnyNetCountries["Ukraine"] = "UA";
    EIso3166OneAlpha2BunnyNetCountries["Portugal"] = "PT";
    EIso3166OneAlpha2BunnyNetCountries["Slovenia"] = "SI";
    EIso3166OneAlpha2BunnyNetCountries["UnitedKingdom"] = "GB";
    EIso3166OneAlpha2BunnyNetCountries["Luxembourg"] = "LU";
    EIso3166OneAlpha2BunnyNetCountries["Spain"] = "ES";
    EIso3166OneAlpha2BunnyNetCountries["France"] = "FR";
    EIso3166OneAlpha2BunnyNetCountries["Italy"] = "IT";
    EIso3166OneAlpha2BunnyNetCountries["RussianFederation"] = "RU";
    EIso3166OneAlpha2BunnyNetCountries["BosniaAndHerzegovina"] = "BA";
    EIso3166OneAlpha2BunnyNetCountries["Norway"] = "NO";
    EIso3166OneAlpha2BunnyNetCountries["CzechRepublic"] = "CZ";
    EIso3166OneAlpha2BunnyNetCountries["Latvia"] = "LV";
    EIso3166OneAlpha2BunnyNetCountries["Bulgaria"] = "BG";
    EIso3166OneAlpha2BunnyNetCountries["Sweden"] = "SE";
    EIso3166OneAlpha2BunnyNetCountries["Austria"] = "AT";
    EIso3166OneAlpha2BunnyNetCountries["Lithuania"] = "LT";
    EIso3166OneAlpha2BunnyNetCountries["Poland"] = "PL";
    EIso3166OneAlpha2BunnyNetCountries["Croatia"] = "HR";
    EIso3166OneAlpha2BunnyNetCountries["Switzerland"] = "CH";
    EIso3166OneAlpha2BunnyNetCountries["Colombia"] = "CO";
    EIso3166OneAlpha2BunnyNetCountries["Argentina"] = "AR";
    EIso3166OneAlpha2BunnyNetCountries["Brazil"] = "BR";
    EIso3166OneAlpha2BunnyNetCountries["Guatemala"] = "GT";
    EIso3166OneAlpha2BunnyNetCountries["Bolivia"] = "BO";
    EIso3166OneAlpha2BunnyNetCountries["Peru"] = "PE";
    EIso3166OneAlpha2BunnyNetCountries["Mexico"] = "MX";
    EIso3166OneAlpha2BunnyNetCountries["PuertoRico"] = "PR";
    EIso3166OneAlpha2BunnyNetCountries["CostaRica"] = "CR";
    EIso3166OneAlpha2BunnyNetCountries["Chile"] = "CL";
    EIso3166OneAlpha2BunnyNetCountries["UnitedStates"] = "US";
    EIso3166OneAlpha2BunnyNetCountries["Canada"] = "CA";
    EIso3166OneAlpha2BunnyNetCountries["Australia"] = "AU";
    EIso3166OneAlpha2BunnyNetCountries["NewZealand"] = "NZ";
    EIso3166OneAlpha2BunnyNetCountries["Egypt"] = "EG";
    EIso3166OneAlpha2BunnyNetCountries["SouthAfrica"] = "ZA";
    EIso3166OneAlpha2BunnyNetCountries["Iraq"] = "IQ";
    EIso3166OneAlpha2BunnyNetCountries["Bahrain"] = "BH";
    EIso3166OneAlpha2BunnyNetCountries["UnitedArabEmirates"] = "AE";
    EIso3166OneAlpha2BunnyNetCountries["Nigeria"] = "NG";
    EIso3166OneAlpha2BunnyNetCountries["Angola"] = "AO";
    EIso3166OneAlpha2BunnyNetCountries["SaudiArabia"] = "SA";
    EIso3166OneAlpha2BunnyNetCountries["Kazakhstan"] = "KZ";
    EIso3166OneAlpha2BunnyNetCountries["Azerbaijan"] = "AZ";
    EIso3166OneAlpha2BunnyNetCountries["Thailand"] = "TH";
    EIso3166OneAlpha2BunnyNetCountries["India"] = "IN";
    EIso3166OneAlpha2BunnyNetCountries["Bangladesh"] = "BD";
    EIso3166OneAlpha2BunnyNetCountries["HongKong"] = "HK";
    EIso3166OneAlpha2BunnyNetCountries["Vietnam"] = "VN";
    EIso3166OneAlpha2BunnyNetCountries["Turkey"] = "TR";
    EIso3166OneAlpha2BunnyNetCountries["Indonesia"] = "ID";
    EIso3166OneAlpha2BunnyNetCountries["Pakistan"] = "PK";
    EIso3166OneAlpha2BunnyNetCountries["Nepal"] = "NP";
    EIso3166OneAlpha2BunnyNetCountries["Malaysia"] = "MY";
    EIso3166OneAlpha2BunnyNetCountries["Philippines"] = "PH";
    EIso3166OneAlpha2BunnyNetCountries["Cyprus"] = "CY";
    EIso3166OneAlpha2BunnyNetCountries["Japan"] = "JP";
    EIso3166OneAlpha2BunnyNetCountries["Cambodia"] = "KH";
    EIso3166OneAlpha2BunnyNetCountries["Korea"] = "KR";
    EIso3166OneAlpha2BunnyNetCountries["Singapore"] = "SG";
    EIso3166OneAlpha2BunnyNetCountries["Taiwan"] = "TW";
    EIso3166OneAlpha2BunnyNetCountries["Georgia"] = "GE";
    EIso3166OneAlpha2BunnyNetCountries["Mongolia"] = "MN";
    EIso3166OneAlpha2BunnyNetCountries["Myanmar"] = "MM";
    EIso3166OneAlpha2BunnyNetCountries["Armenia"] = "AM";
})(EIso3166OneAlpha2BunnyNetCountries || (EIso3166OneAlpha2BunnyNetCountries = {}));
var EIso3166OneAlpha2GcoreCountries;
(function(EIso3166OneAlpha2GcoreCountries) {
    EIso3166OneAlpha2GcoreCountries["UnitedStates"] = "US";
    EIso3166OneAlpha2GcoreCountries["Mexico"] = "MX";
    EIso3166OneAlpha2GcoreCountries["Paraguay"] = "PY";
    EIso3166OneAlpha2GcoreCountries["Colombia"] = "CO";
    EIso3166OneAlpha2GcoreCountries["Argentina"] = "AR";
    EIso3166OneAlpha2GcoreCountries["Brazil"] = "BR";
    EIso3166OneAlpha2GcoreCountries["Guatemala"] = "GT";
    EIso3166OneAlpha2GcoreCountries["Ecuador"] = "EC";
    EIso3166OneAlpha2GcoreCountries["Peru"] = "PE";
    EIso3166OneAlpha2GcoreCountries["Panama"] = "PA";
    EIso3166OneAlpha2GcoreCountries["France"] = "FR";
    EIso3166OneAlpha2GcoreCountries["CostaRica"] = "CR";
    EIso3166OneAlpha2GcoreCountries["ElSalvador"] = "SV";
    EIso3166OneAlpha2GcoreCountries["Chile"] = "CL";
    EIso3166OneAlpha2GcoreCountries["Honduras"] = "HN";
    EIso3166OneAlpha2GcoreCountries["DominicanRepublic"] = "DO";
    EIso3166OneAlpha2GcoreCountries["Netherlands"] = "NL";
    EIso3166OneAlpha2GcoreCountries["Greece"] = "GR";
    EIso3166OneAlpha2GcoreCountries["Serbia"] = "RS";
    EIso3166OneAlpha2GcoreCountries["Belgium"] = "BE";
    EIso3166OneAlpha2GcoreCountries["Romania"] = "RO";
    EIso3166OneAlpha2GcoreCountries["Hungary"] = "HU";
    EIso3166OneAlpha2GcoreCountries["Moldova"] = "MD";
    EIso3166OneAlpha2GcoreCountries["Ireland"] = "IE";
    EIso3166OneAlpha2GcoreCountries["Germany"] = "DE";
    EIso3166OneAlpha2GcoreCountries["Finland"] = "FI";
    EIso3166OneAlpha2GcoreCountries["Ukraine"] = "UA";
    EIso3166OneAlpha2GcoreCountries["Cyprus"] = "CY";
    EIso3166OneAlpha2GcoreCountries["UnitedKingdom"] = "GB";
    EIso3166OneAlpha2GcoreCountries["Luxembourg"] = "LU";
    EIso3166OneAlpha2GcoreCountries["Spain"] = "ES";
    EIso3166OneAlpha2GcoreCountries["Italy"] = "IT";
    EIso3166OneAlpha2GcoreCountries["CzechRepublic"] = "CZ";
    EIso3166OneAlpha2GcoreCountries["Latvia"] = "LV";
    EIso3166OneAlpha2GcoreCountries["Bulgaria"] = "BG";
    EIso3166OneAlpha2GcoreCountries["Norway"] = "NO";
    EIso3166OneAlpha2GcoreCountries["Switzerland"] = "CH";
    EIso3166OneAlpha2GcoreCountries["Georgia"] = "GE";
    EIso3166OneAlpha2GcoreCountries["Austria"] = "AT";
    EIso3166OneAlpha2GcoreCountries["Poland"] = "PL";
    EIso3166OneAlpha2GcoreCountries["Jordan"] = "JO";
    EIso3166OneAlpha2GcoreCountries["Iraq"] = "IQ";
    EIso3166OneAlpha2GcoreCountries["Qatar"] = "QA";
    EIso3166OneAlpha2GcoreCountries["UnitedArabEmirates"] = "AE";
    EIso3166OneAlpha2GcoreCountries["Turkey"] = "TR";
    EIso3166OneAlpha2GcoreCountries["Kuwait"] = "KW";
    EIso3166OneAlpha2GcoreCountries["Bahrain"] = "BH";
    EIso3166OneAlpha2GcoreCountries["Oman"] = "OM";
    EIso3166OneAlpha2GcoreCountries["SaudiArabia"] = "SA";
    EIso3166OneAlpha2GcoreCountries["Yemen"] = "YE";
    EIso3166OneAlpha2GcoreCountries["Israel"] = "IL";
    EIso3166OneAlpha2GcoreCountries["PalestinianTerritoryOccupied"] = "PS";
    EIso3166OneAlpha2GcoreCountries["Armenia"] = "AM";
    EIso3166OneAlpha2GcoreCountries["CoteDIvoire"] = "CI";
    EIso3166OneAlpha2GcoreCountries["Egypt"] = "EG";
    EIso3166OneAlpha2GcoreCountries["SouthAfrica"] = "ZA";
    EIso3166OneAlpha2GcoreCountries["Zimbabwe"] = "ZW";
    EIso3166OneAlpha2GcoreCountries["Uganda"] = "UG";
    EIso3166OneAlpha2GcoreCountries["Nigeria"] = "NG";
    EIso3166OneAlpha2GcoreCountries["Togo"] = "TG";
    EIso3166OneAlpha2GcoreCountries["Angola"] = "AO";
    EIso3166OneAlpha2GcoreCountries["Zambia"] = "ZM";
    EIso3166OneAlpha2GcoreCountries["Kenya"] = "KE";
    EIso3166OneAlpha2GcoreCountries["BurkinaFaso"] = "BF";
    EIso3166OneAlpha2GcoreCountries["Morocco"] = "MA";
    EIso3166OneAlpha2GcoreCountries["Mauritius"] = "MU";
    EIso3166OneAlpha2GcoreCountries["LibyanArabJamahiriya"] = "LY";
    EIso3166OneAlpha2GcoreCountries["Namibia"] = "NA";
    EIso3166OneAlpha2GcoreCountries["Ghana"] = "GH";
    EIso3166OneAlpha2GcoreCountries["Ethiopia"] = "ET";
    EIso3166OneAlpha2GcoreCountries["Sudan"] = "SD";
    EIso3166OneAlpha2GcoreCountries["Tunisia"] = "TN";
    EIso3166OneAlpha2GcoreCountries["Kazakhstan"] = "KZ";
    EIso3166OneAlpha2GcoreCountries["Azerbaijan"] = "AZ";
    EIso3166OneAlpha2GcoreCountries["Thailand"] = "TH";
    EIso3166OneAlpha2GcoreCountries["Kyrgyzstan"] = "KG";
    EIso3166OneAlpha2GcoreCountries["SriLanka"] = "LK";
    EIso3166OneAlpha2GcoreCountries["Philippines"] = "PH";
    EIso3166OneAlpha2GcoreCountries["Bangladesh"] = "BD";
    EIso3166OneAlpha2GcoreCountries["Tajikistan"] = "TJ";
    EIso3166OneAlpha2GcoreCountries["Vietnam"] = "VN";
    EIso3166OneAlpha2GcoreCountries["China"] = "CN";
    EIso3166OneAlpha2GcoreCountries["Nepal"] = "NP";
    EIso3166OneAlpha2GcoreCountries["India"] = "IN";
    EIso3166OneAlpha2GcoreCountries["Japan"] = "JP";
    EIso3166OneAlpha2GcoreCountries["Cambodia"] = "KH";
    EIso3166OneAlpha2GcoreCountries["Korea"] = "KR";
    EIso3166OneAlpha2GcoreCountries["Singapore"] = "SG";
    EIso3166OneAlpha2GcoreCountries["Uzbekistan"] = "UZ";
    EIso3166OneAlpha2GcoreCountries["Mongolia"] = "MN";
    EIso3166OneAlpha2GcoreCountries["Bhutan"] = "BT";
    EIso3166OneAlpha2GcoreCountries["Indonesia"] = "ID";
    EIso3166OneAlpha2GcoreCountries["Pakistan"] = "PK";
    EIso3166OneAlpha2GcoreCountries["Taiwan"] = "TW";
    EIso3166OneAlpha2GcoreCountries["Australia"] = "AU";
    EIso3166OneAlpha2GcoreCountries["NewZealand"] = "NZ";
})(EIso3166OneAlpha2GcoreCountries || (EIso3166OneAlpha2GcoreCountries = {}));
var EIso3166OneAlpha2MicrosoftAzureCountries;
(function(EIso3166OneAlpha2MicrosoftAzureCountries) {
    EIso3166OneAlpha2MicrosoftAzureCountries["Australia"] = "AU";
    EIso3166OneAlpha2MicrosoftAzureCountries["Austria"] = "AT";
    EIso3166OneAlpha2MicrosoftAzureCountries["Belgium"] = "BE";
    EIso3166OneAlpha2MicrosoftAzureCountries["Brazil"] = "BR";
    EIso3166OneAlpha2MicrosoftAzureCountries["Canada"] = "CA";
    EIso3166OneAlpha2MicrosoftAzureCountries["Chile"] = "CL";
    EIso3166OneAlpha2MicrosoftAzureCountries["China"] = "CN";
    EIso3166OneAlpha2MicrosoftAzureCountries["Denmark"] = "DK";
    EIso3166OneAlpha2MicrosoftAzureCountries["Finland"] = "FI";
    EIso3166OneAlpha2MicrosoftAzureCountries["France"] = "FR";
    EIso3166OneAlpha2MicrosoftAzureCountries["Germany"] = "DE";
    EIso3166OneAlpha2MicrosoftAzureCountries["Greece"] = "GR";
    EIso3166OneAlpha2MicrosoftAzureCountries["HongKong"] = "HK";
    EIso3166OneAlpha2MicrosoftAzureCountries["India"] = "IN";
    EIso3166OneAlpha2MicrosoftAzureCountries["Indonesia"] = "ID";
    EIso3166OneAlpha2MicrosoftAzureCountries["Ireland"] = "IE";
    EIso3166OneAlpha2MicrosoftAzureCountries["Israel"] = "IL";
    EIso3166OneAlpha2MicrosoftAzureCountries["Italy"] = "IT";
    EIso3166OneAlpha2MicrosoftAzureCountries["Japan"] = "JP";
    EIso3166OneAlpha2MicrosoftAzureCountries["Malaysia"] = "MY";
    EIso3166OneAlpha2MicrosoftAzureCountries["Mexico"] = "MX";
    EIso3166OneAlpha2MicrosoftAzureCountries["Netherlands"] = "NL";
    EIso3166OneAlpha2MicrosoftAzureCountries["NewZealand"] = "NZ";
    EIso3166OneAlpha2MicrosoftAzureCountries["Norway"] = "NO";
    EIso3166OneAlpha2MicrosoftAzureCountries["Poland"] = "PL";
    EIso3166OneAlpha2MicrosoftAzureCountries["Qatar"] = "QA";
    EIso3166OneAlpha2MicrosoftAzureCountries["SaudiArabia"] = "SA";
    EIso3166OneAlpha2MicrosoftAzureCountries["Singapore"] = "SG";
    EIso3166OneAlpha2MicrosoftAzureCountries["SouthAfrica"] = "ZA";
    EIso3166OneAlpha2MicrosoftAzureCountries["Korea"] = "KR";
    EIso3166OneAlpha2MicrosoftAzureCountries["Spain"] = "ES";
    EIso3166OneAlpha2MicrosoftAzureCountries["Sweden"] = "SE";
    EIso3166OneAlpha2MicrosoftAzureCountries["Switzerland"] = "CH";
    EIso3166OneAlpha2MicrosoftAzureCountries["Taiwan"] = "TW";
    EIso3166OneAlpha2MicrosoftAzureCountries["UnitedArabEmirates"] = "AE";
    EIso3166OneAlpha2MicrosoftAzureCountries["UnitedKingdom"] = "GB";
    EIso3166OneAlpha2MicrosoftAzureCountries["UnitedStates"] = "US";
})(EIso3166OneAlpha2MicrosoftAzureCountries || (EIso3166OneAlpha2MicrosoftAzureCountries = {}));
export { EIso3166OneAlpha2, EIso3166OneAlpha2AWSCountries, EIso3166OneAlpha2AdequacyCountries, EIso3166OneAlpha2CloudflareCountries, EIso3166OneAlpha2CountryGroup, EIso3166OneAlpha2EuCountries, EIso3166OneAlpha2EeaCountries, EIso3166OneAlpha2FastlyCountries, EIso3166OneAlpha2GoogleCloudCountries, EIso3166OneAlpha2AutomatticCountries, EIso3166OneAlpha2AkamaiCountries, EIso3166OneAlpha2BunnyNetCountries, EIso3166OneAlpha2GcoreCountries, EIso3166OneAlpha2MicrosoftAzureCountries };
