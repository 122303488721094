var EIso3166OneAlpha2English;
(function(EIso3166OneAlpha2English) {
    EIso3166OneAlpha2English["AF"] = "Afghanistan";
    EIso3166OneAlpha2English["AX"] = "Aland Islands";
    EIso3166OneAlpha2English["AL"] = "Albania";
    EIso3166OneAlpha2English["DZ"] = "Algeria";
    EIso3166OneAlpha2English["AS"] = "American Samoa";
    EIso3166OneAlpha2English["AD"] = "Andorra";
    EIso3166OneAlpha2English["AO"] = "Angola";
    EIso3166OneAlpha2English["AI"] = "Anguilla";
    EIso3166OneAlpha2English["AQ"] = "Antarctica";
    EIso3166OneAlpha2English["AG"] = "Antigua And Barbuda";
    EIso3166OneAlpha2English["AR"] = "Argentina";
    EIso3166OneAlpha2English["AM"] = "Armenia";
    EIso3166OneAlpha2English["AW"] = "Aruba";
    EIso3166OneAlpha2English["AU"] = "Australia";
    EIso3166OneAlpha2English["AT"] = "Austria";
    EIso3166OneAlpha2English["AZ"] = "Azerbaijan";
    EIso3166OneAlpha2English["BS"] = "Bahamas";
    EIso3166OneAlpha2English["BH"] = "Bahrain";
    EIso3166OneAlpha2English["BD"] = "Bangladesh";
    EIso3166OneAlpha2English["BB"] = "Barbados";
    EIso3166OneAlpha2English["BY"] = "Belarus";
    EIso3166OneAlpha2English["BE"] = "Belgium";
    EIso3166OneAlpha2English["BZ"] = "Belize";
    EIso3166OneAlpha2English["BJ"] = "Benin";
    EIso3166OneAlpha2English["BM"] = "Bermuda";
    EIso3166OneAlpha2English["BT"] = "Bhutan";
    EIso3166OneAlpha2English["BO"] = "Bolivia";
    EIso3166OneAlpha2English["BA"] = "Bosnia and Herzegovina";
    EIso3166OneAlpha2English["BW"] = "Botswana";
    EIso3166OneAlpha2English["BV"] = "Bouvet Island";
    EIso3166OneAlpha2English["BR"] = "Brazil";
    EIso3166OneAlpha2English["IO"] = "British Indian Ocean Territory";
    EIso3166OneAlpha2English["BN"] = "Brunei Darussalam";
    EIso3166OneAlpha2English["BG"] = "Bulgaria";
    EIso3166OneAlpha2English["BF"] = "Burkina Faso";
    EIso3166OneAlpha2English["BI"] = "Burundi";
    EIso3166OneAlpha2English["KH"] = "Cambodia";
    EIso3166OneAlpha2English["CM"] = "Cameroon";
    EIso3166OneAlpha2English["CA"] = "Canada";
    EIso3166OneAlpha2English["CV"] = "Cape Verde";
    EIso3166OneAlpha2English["KY"] = "Cayman Islands";
    EIso3166OneAlpha2English["CF"] = "Central African Republic";
    EIso3166OneAlpha2English["TD"] = "Chad";
    EIso3166OneAlpha2English["CL"] = "Chile";
    EIso3166OneAlpha2English["CN"] = "China";
    EIso3166OneAlpha2English["CX"] = "Christmas Island";
    EIso3166OneAlpha2English["CC"] = "Cocos (Keeling) Islands";
    EIso3166OneAlpha2English["CO"] = "Colombia";
    EIso3166OneAlpha2English["KM"] = "Comoros";
    EIso3166OneAlpha2English["CG"] = "Congo";
    EIso3166OneAlpha2English["CD"] = "Congo (Democratic Republic)";
    EIso3166OneAlpha2English["CK"] = "Cook Islands";
    EIso3166OneAlpha2English["CR"] = "Costa Rica";
    EIso3166OneAlpha2English["CI"] = "Cote D'Ivoire";
    EIso3166OneAlpha2English["HR"] = "Croatia";
    EIso3166OneAlpha2English["CU"] = "Cuba";
    EIso3166OneAlpha2English["CY"] = "Cyprus";
    EIso3166OneAlpha2English["CZ"] = "Czech Republic";
    EIso3166OneAlpha2English["DK"] = "Denmark";
    EIso3166OneAlpha2English["DJ"] = "Djibouti";
    EIso3166OneAlpha2English["DM"] = "Dominica";
    EIso3166OneAlpha2English["DO"] = "Dominican Republic";
    EIso3166OneAlpha2English["EC"] = "Ecuador";
    EIso3166OneAlpha2English["EG"] = "Egypt";
    EIso3166OneAlpha2English["SV"] = "El Salvador";
    EIso3166OneAlpha2English["GQ"] = "Equatorial Guinea";
    EIso3166OneAlpha2English["ER"] = "Eritrea";
    EIso3166OneAlpha2English["EE"] = "Estonia";
    EIso3166OneAlpha2English["ET"] = "Ethiopia";
    EIso3166OneAlpha2English["FK"] = "Falkland Islands (Malvinas)";
    EIso3166OneAlpha2English["FO"] = "Faroe Islands";
    EIso3166OneAlpha2English["FJ"] = "Fiji";
    EIso3166OneAlpha2English["FI"] = "Finland";
    EIso3166OneAlpha2English["FR"] = "France";
    EIso3166OneAlpha2English["GF"] = "French Guiana";
    EIso3166OneAlpha2English["PF"] = "French Polynesia";
    EIso3166OneAlpha2English["TF"] = "French Southern Territories";
    EIso3166OneAlpha2English["GA"] = "Gabon";
    EIso3166OneAlpha2English["GM"] = "Gambia";
    EIso3166OneAlpha2English["GE"] = "Georgia";
    EIso3166OneAlpha2English["DE"] = "Germany";
    EIso3166OneAlpha2English["GH"] = "Ghana";
    EIso3166OneAlpha2English["GI"] = "Gibraltar";
    EIso3166OneAlpha2English["GR"] = "Greece";
    EIso3166OneAlpha2English["GL"] = "Greenland";
    EIso3166OneAlpha2English["GD"] = "Grenada";
    EIso3166OneAlpha2English["GP"] = "Guadeloupe";
    EIso3166OneAlpha2English["GU"] = "Guam";
    EIso3166OneAlpha2English["GT"] = "Guatemala";
    EIso3166OneAlpha2English["GG"] = "Guernsey";
    EIso3166OneAlpha2English["GN"] = "Guinea";
    EIso3166OneAlpha2English["GW"] = "Guinea-Bissau";
    EIso3166OneAlpha2English["GY"] = "Guyana";
    EIso3166OneAlpha2English["HT"] = "Haiti";
    EIso3166OneAlpha2English["HM"] = "Heard Island & Mcdonald Islands";
    EIso3166OneAlpha2English["VA"] = "Holy See (Vatican City State)";
    EIso3166OneAlpha2English["HN"] = "Honduras";
    EIso3166OneAlpha2English["HK"] = "Hong Kong";
    EIso3166OneAlpha2English["HU"] = "Hungary";
    EIso3166OneAlpha2English["IS"] = "Iceland";
    EIso3166OneAlpha2English["IN"] = "India";
    EIso3166OneAlpha2English["ID"] = "Indonesia";
    EIso3166OneAlpha2English["IR"] = "Islamic Republic of Iran";
    EIso3166OneAlpha2English["IQ"] = "Iraq";
    EIso3166OneAlpha2English["IE"] = "Ireland";
    EIso3166OneAlpha2English["IM"] = "Isle Of Man";
    EIso3166OneAlpha2English["IL"] = "Israel";
    EIso3166OneAlpha2English["IT"] = "Italy";
    EIso3166OneAlpha2English["JM"] = "Jamaica";
    EIso3166OneAlpha2English["JP"] = "Japan";
    EIso3166OneAlpha2English["JE"] = "Jersey";
    EIso3166OneAlpha2English["JO"] = "Jordan";
    EIso3166OneAlpha2English["KZ"] = "Kazakhstan";
    EIso3166OneAlpha2English["KE"] = "Kenya";
    EIso3166OneAlpha2English["KI"] = "Kiribati";
    EIso3166OneAlpha2English["KR"] = "Korea";
    EIso3166OneAlpha2English["KP"] = "North Korea";
    EIso3166OneAlpha2English["KW"] = "Kuwait";
    EIso3166OneAlpha2English["KG"] = "Kyrgyzstan";
    EIso3166OneAlpha2English["LA"] = "Lao People's Democratic Republic";
    EIso3166OneAlpha2English["LV"] = "Latvia";
    EIso3166OneAlpha2English["LB"] = "Lebanon";
    EIso3166OneAlpha2English["LS"] = "Lesotho";
    EIso3166OneAlpha2English["LR"] = "Liberia";
    EIso3166OneAlpha2English["LY"] = "Libyan Arab Jamahiriya";
    EIso3166OneAlpha2English["LI"] = "Liechtenstein";
    EIso3166OneAlpha2English["LT"] = "Lithuania";
    EIso3166OneAlpha2English["LU"] = "Luxembourg";
    EIso3166OneAlpha2English["MO"] = "Macao";
    EIso3166OneAlpha2English["MK"] = "Macedonia";
    EIso3166OneAlpha2English["MG"] = "Madagascar";
    EIso3166OneAlpha2English["MW"] = "Malawi";
    EIso3166OneAlpha2English["MY"] = "Malaysia";
    EIso3166OneAlpha2English["MV"] = "Maldives";
    EIso3166OneAlpha2English["ML"] = "Mali";
    EIso3166OneAlpha2English["MT"] = "Malta";
    EIso3166OneAlpha2English["MH"] = "Marshall Islands";
    EIso3166OneAlpha2English["MQ"] = "Martinique";
    EIso3166OneAlpha2English["MR"] = "Mauritania";
    EIso3166OneAlpha2English["MU"] = "Mauritius";
    EIso3166OneAlpha2English["YT"] = "Mayotte";
    EIso3166OneAlpha2English["MX"] = "Mexico";
    EIso3166OneAlpha2English["FM"] = "Federated States of Micronesia";
    EIso3166OneAlpha2English["MD"] = "Moldova";
    EIso3166OneAlpha2English["MC"] = "Monaco";
    EIso3166OneAlpha2English["MN"] = "Mongolia";
    EIso3166OneAlpha2English["ME"] = "Montenegro";
    EIso3166OneAlpha2English["MS"] = "Montserrat";
    EIso3166OneAlpha2English["MA"] = "Morocco";
    EIso3166OneAlpha2English["MZ"] = "Mozambique";
    EIso3166OneAlpha2English["MM"] = "Myanmar";
    EIso3166OneAlpha2English["NA"] = "Namibia";
    EIso3166OneAlpha2English["NR"] = "Nauru";
    EIso3166OneAlpha2English["NP"] = "Nepal";
    EIso3166OneAlpha2English["NL"] = "Netherlands";
    EIso3166OneAlpha2English["AN"] = "Netherlands Antilles";
    EIso3166OneAlpha2English["NC"] = "New Caledonia";
    EIso3166OneAlpha2English["NZ"] = "New Zealand";
    EIso3166OneAlpha2English["NI"] = "Nicaragua";
    EIso3166OneAlpha2English["NE"] = "Niger";
    EIso3166OneAlpha2English["NG"] = "Nigeria";
    EIso3166OneAlpha2English["NU"] = "Niue";
    EIso3166OneAlpha2English["NF"] = "Norfolk Island";
    EIso3166OneAlpha2English["MP"] = "Northern Mariana Islands";
    EIso3166OneAlpha2English["NO"] = "Norway";
    EIso3166OneAlpha2English["OM"] = "Oman";
    EIso3166OneAlpha2English["PK"] = "Pakistan";
    EIso3166OneAlpha2English["PW"] = "Palau";
    EIso3166OneAlpha2English["PS"] = "Palestinian Territory (Occupied)";
    EIso3166OneAlpha2English["PA"] = "Panama";
    EIso3166OneAlpha2English["PG"] = "Papua New Guinea";
    EIso3166OneAlpha2English["PY"] = "Paraguay";
    EIso3166OneAlpha2English["PE"] = "Peru";
    EIso3166OneAlpha2English["PH"] = "Philippines";
    EIso3166OneAlpha2English["PN"] = "Pitcairn";
    EIso3166OneAlpha2English["PL"] = "Poland";
    EIso3166OneAlpha2English["PT"] = "Portugal";
    EIso3166OneAlpha2English["PR"] = "Puerto Rico";
    EIso3166OneAlpha2English["QA"] = "Qatar";
    EIso3166OneAlpha2English["RE"] = "Reunion";
    EIso3166OneAlpha2English["RO"] = "Romania";
    EIso3166OneAlpha2English["RU"] = "Russian Federation";
    EIso3166OneAlpha2English["RW"] = "Rwanda";
    EIso3166OneAlpha2English["BL"] = "Saint Barthelemy";
    EIso3166OneAlpha2English["SH"] = "Saint Helena";
    EIso3166OneAlpha2English["KN"] = "Saint Kitts And Nevis";
    EIso3166OneAlpha2English["LC"] = "Saint Lucia";
    EIso3166OneAlpha2English["MF"] = "Saint Martin";
    EIso3166OneAlpha2English["PM"] = "Saint Pierre And Miquelon";
    EIso3166OneAlpha2English["VC"] = "Saint Vincent And Grenadines";
    EIso3166OneAlpha2English["WS"] = "Samoa";
    EIso3166OneAlpha2English["SM"] = "San Marino";
    EIso3166OneAlpha2English["ST"] = "Sao Tome And Principe";
    EIso3166OneAlpha2English["SA"] = "Saudi Arabia";
    EIso3166OneAlpha2English["SN"] = "Senegal";
    EIso3166OneAlpha2English["RS"] = "Serbia";
    EIso3166OneAlpha2English["SC"] = "Seychelles";
    EIso3166OneAlpha2English["SL"] = "Sierra Leone";
    EIso3166OneAlpha2English["SG"] = "Singapore";
    EIso3166OneAlpha2English["SK"] = "Slovakia";
    EIso3166OneAlpha2English["SI"] = "Slovenia";
    EIso3166OneAlpha2English["SB"] = "Solomon Islands";
    EIso3166OneAlpha2English["SO"] = "Somalia";
    EIso3166OneAlpha2English["ZA"] = "South Africa";
    EIso3166OneAlpha2English["GS"] = "South Georgia And Sandwich Isl.";
    EIso3166OneAlpha2English["ES"] = "Spain";
    EIso3166OneAlpha2English["LK"] = "Sri Lanka";
    EIso3166OneAlpha2English["SD"] = "Sudan";
    EIso3166OneAlpha2English["SR"] = "Suriname";
    EIso3166OneAlpha2English["SJ"] = "Svalbard And Jan Mayen";
    EIso3166OneAlpha2English["SZ"] = "Swaziland";
    EIso3166OneAlpha2English["SE"] = "Sweden";
    EIso3166OneAlpha2English["CH"] = "Switzerland";
    EIso3166OneAlpha2English["SY"] = "Syrian Arab Republic";
    EIso3166OneAlpha2English["TW"] = "Taiwan";
    EIso3166OneAlpha2English["TJ"] = "Tajikistan";
    EIso3166OneAlpha2English["TZ"] = "Tanzania";
    EIso3166OneAlpha2English["TH"] = "Thailand";
    EIso3166OneAlpha2English["TL"] = "Timor-Leste";
    EIso3166OneAlpha2English["TG"] = "Togo";
    EIso3166OneAlpha2English["TK"] = "Tokelau";
    EIso3166OneAlpha2English["TO"] = "Tonga";
    EIso3166OneAlpha2English["TT"] = "Trinidad And Tobago";
    EIso3166OneAlpha2English["TN"] = "Tunisia";
    EIso3166OneAlpha2English["TR"] = "Turkey";
    EIso3166OneAlpha2English["TM"] = "Turkmenistan";
    EIso3166OneAlpha2English["TC"] = "Turks And Caicos Islands";
    EIso3166OneAlpha2English["TV"] = "Tuvalu";
    EIso3166OneAlpha2English["UG"] = "Uganda";
    EIso3166OneAlpha2English["UA"] = "Ukraine";
    EIso3166OneAlpha2English["AE"] = "United Arab Emirates";
    EIso3166OneAlpha2English["GB"] = "United Kingdom";
    EIso3166OneAlpha2English["US"] = "United States";
    EIso3166OneAlpha2English["UM"] = "United States Outlying Islands";
    EIso3166OneAlpha2English["UY"] = "Uruguay";
    EIso3166OneAlpha2English["UZ"] = "Uzbekistan";
    EIso3166OneAlpha2English["VU"] = "Vanuatu";
    EIso3166OneAlpha2English["VE"] = "Venezuela";
    EIso3166OneAlpha2English["VN"] = "Vietnam";
    EIso3166OneAlpha2English["VG"] = "Virgin Islands (British)";
    EIso3166OneAlpha2English["VI"] = "Virgin Islands (U.S.)";
    EIso3166OneAlpha2English["WF"] = "Wallis And Futuna";
    EIso3166OneAlpha2English["EH"] = "Western Sahara";
    EIso3166OneAlpha2English["YE"] = "Yemen";
    EIso3166OneAlpha2English["ZM"] = "Zambia";
    EIso3166OneAlpha2English["ZW"] = "Zimbabwe";
})(EIso3166OneAlpha2English || (EIso3166OneAlpha2English = {}));
var EIso3166OneAlpha2CountryGroupEnglish;
(function(EIso3166OneAlpha2CountryGroupEnglish) {
    EIso3166OneAlpha2CountryGroupEnglish["AWS"] = "AWS";
    EIso3166OneAlpha2CountryGroupEnglish["CLOUDFLARE"] = "Cloudflare";
    EIso3166OneAlpha2CountryGroupEnglish["EU"] = "European Union (EU)";
    EIso3166OneAlpha2CountryGroupEnglish["EEA"] = "European Economic Area (EEA)";
    EIso3166OneAlpha2CountryGroupEnglish["FASTLY"] = "Fastly";
    EIso3166OneAlpha2CountryGroupEnglish["GOOGLECLOUD"] = "Google Cloud";
    EIso3166OneAlpha2CountryGroupEnglish["AUTOMATTIC"] = "Automattic";
    EIso3166OneAlpha2CountryGroupEnglish["AKAMAI"] = "Akamai";
    EIso3166OneAlpha2CountryGroupEnglish["BUNNYNET"] = "Bunny.net";
    EIso3166OneAlpha2CountryGroupEnglish["GCORE"] = "Gcore";
    EIso3166OneAlpha2CountryGroupEnglish["WPMUDEV"] = "WPMU DEV";
    EIso3166OneAlpha2CountryGroupEnglish["MICROSOFTAZURE"] = "Microsoft Azure";
})(EIso3166OneAlpha2CountryGroupEnglish || (EIso3166OneAlpha2CountryGroupEnglish = {}));
export { EIso3166OneAlpha2English, EIso3166OneAlpha2CountryGroupEnglish };
